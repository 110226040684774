import React, { Component } from 'react';

class SvgBadge extends Component {
  constructor(props) {
    super(props);

    this.circle = null;
  }

  componentDidMount() {
    const length = this.circle.getTotalLength() + 1;
    this.circle.setAttribute(
      'style',
      'stroke-dasharray: ' + length + '; stroke-dashoffset: ' + length
    );
  }

  render() {
    return (
      <svg
        className="nwaTip_badge"
        width="30"
        height="30"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(1 1)">
            <path
              d="M19.44 10.3c0 .52-.07.98-.23 1.39-.16.4-.38.78-.68 1.12-.3.35-.77.76-1.42 1.23-.79.57-1.3.98-1.56 1.25-.25.26-.45.54-.6.85-.14.3-.22.66-.23 1.05h-1.87a3.84 3.84 0 0 1 .93-2.43 9.2 9.2 0 0 1 1.43-1.26c.77-.55 1.27-.96 1.5-1.23.24-.26.43-.54.58-.85.14-.3.21-.65.21-1.04 0-.77-.28-1.37-.85-1.79a3.8 3.8 0 0 0-2.3-.62c-.96 0-1.74.26-2.35.78-.6.52-.95 1.23-1.05 2.12L9 10.75c.18-1.4.75-2.49 1.7-3.25a5.65 5.65 0 0 1 3.64-1.13 6.1 6.1 0 0 1 3.75 1.03 3.42 3.42 0 0 1 1.36 2.9zM12.77 21v-2.15h2.08V21h-2.08z"
              fill="#FAFAFA"
            />
            <circle
              ref={el => {
                this.circle = el;
              }}
              stroke="#65D6C6"
              strokeWidth="2"
              cx="14"
              cy="14"
              r="14"
            />
          </g>
        </g>
      </svg>
    );
  }
}

export default SvgBadge;
