import React from 'react';

const SvgFolder = () => {
  return (
    <svg
      className="nwaResearch_notification_folder"
      viewBox="0 0 136 105"
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="1.41"
    >
      <path
        d="M3.26 92.27l-.01.03.03.43c0 .15.01.3.03.44l.08.54.07.41.13.52.1.4.19.5.14.38c.07.17.15.32.23.48.05.12.1.24.17.36l.27.45.2.33.32.43.23.3c.11.14.24.27.36.4l.26.28.39.35.28.26.43.31.3.23.46.28.33.2.5.23.34.16.52.19.36.12c.18.06.37.1.56.14l.36.1c.2.04.41.06.62.09l.34.05c.32.03.65.04.99.04h94.18c.13 0 .25-.03.39-.03 1.21-.14 5.06-.8 7.17-3.76.41-.57 1.1-2.43 2-5.2l3.37.02-.15.44a12.69 12.69 0 0 1-12.72 11.8H12.83C5.64 104.96 0 99.3 0 92.07v-72C0 13.4 3.83 8.06 10.26 7.3c0 0 2.04-.1 2.58-.1H39.3c4.46 0 8.5 2.13 10.78 5.7l3.6 5.63a5.35 5.35 0 0 0 4.23 2.17h49.11c1 0 2 .12 2.96.35a12.7 12.7 0 0 1 9.88 12.53v1.34h1.46L120 35h-4l-1.04-.07h1.65v-1.35a9.5 9.5 0 0 0-6.63-9.16s-1.92-.46-2.96-.46H57.91c-2.69 0-5.2-1.3-6.9-3.58l-3.66-5.72a9.43 9.43 0 0 0-8.05-4.2H12.84l-1.58.14a9.48 9.48 0 0 0-8.01 9.48v72.19z"
        fill="#65d6c6"
      />
      <path
        className="nwaResearch_notification_folder_fold"
        d="M121.32 34.92l14.17-.02-.5 2.03c-1.21 4.84-10.06 40.2-15.04 55.8l-3.38-.02v-.01c.92-2.85 2.05-6.63 3.29-11.03a1670 1670 0 0 0 11.46-43.5h-9.35v.01l-11.99.01-92.02.1-6.7 24.59-8 29.39h-.01V79.94l8.01-29.42 4.22-15.49 94.5-.1h4.98L116 35h4l1.32-.08z"
        fill="#65d6c6"
      />
      <clipPath id="nwaResearch_notification_folder_clip1">
        <path d="M109.95 97.92V32.9h-98.9v64.95l98.9.06v.01z" />
      </clipPath>
      <g
        className="nwaResearch_notification_folder_paper"
        clipPath="url(#nwaResearch_notification_folder_clip1)"
      >
        <path
          d="M11.26 50.52L15.5 34.9h94.45l10.02-71.27H1.03L11.26 34.9v15.62z"
          fill="#fff"
          fillOpacity=".35"
        />
      </g>
    </svg>
  );
};

export default SvgFolder;
