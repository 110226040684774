import slugify from 'slugify';

import scenes from '../scenes';

// import userAvatar from '../images/avatars/n.png';

// Actions
const SCENE_IN = 'nwa/game/SCENE_IN';
const SCENE_OUT = 'nwa/game/SCENE_OUT';
const STEP_FORWARD = 'nwa/game/STEP_FORWARD';
const RESET = 'nwa/game/RESET';
const UPDATE_USER = 'nwa/game/UPDATE_USER';

// Initial state
const startScene = 'start';
// const startScene = 'suspect-colleague-2';
const initialState = {
  currentScene: startScene,
  currentStep: -1,
  exitingScene: null,
  user: null,
  // user: {
  //   userName: 'Manuele',
  //   userNameSlug: 'manuele',
  //   userCompany: 'Latte',
  //   userCompanySlug: 'latte',
  //   userColleague: 'Michela',
  //   userRelative: 'mamma',
  //   userAvatar: userAvatar, // DELETE UP ABOVE!!!!!!
  // },
};

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SCENE_IN:
      if (scenes.get(action.payload))
        return { ...state, currentScene: action.payload, currentStep: -1 };
      return state;

    case SCENE_OUT:
      return { ...state, exitingScene: state.currentScene };

    case STEP_FORWARD:
      return { ...state, currentStep: state.currentStep + 1 };

    case RESET:
      return {
        ...state,
        currentScene: startScene,
        currentStep: -1,
        exitingScene: null,
      };

    case UPDATE_USER:
      const user = action.payload;
      if (!user.userNameSlug)
        user.userNameSlug = slugify(user.userName.toLowerCase());
      if (!user.userCompanySlug)
        user.userCompanySlug = slugify(user.userCompany.toLowerCase());
      return { ...state, user };

    default:
      return state;
  }
}

// Action creators
export function sceneIn(scene) {
  return {
    type: SCENE_IN,
    payload: scene,
  };
}

export function sceneOut() {
  return {
    type: SCENE_OUT,
  };
}

export function stepForward() {
  return {
    type: STEP_FORWARD,
  };
}

export function resetGame() {
  return {
    type: RESET,
  };
}

export function updateUser(user) {
  return {
    type: UPDATE_USER,
    payload: user,
  };
}

// Side effects
// e.g. thunks, epics, etc
export function sceneChange(scene) {
  return dispatch => {
    dispatch(sceneOut());
    setTimeout(() => {
      dispatch(sceneIn(scene));
    }, 1200);
  };
}
