import React from 'react';

import letter from '../../../images/letter.min.png';

const Letter = () => (
  <div className="nwaFinal_letter">
    <img src={letter} alt="Una lettera di licenziamento rivolta a te." />
  </div>
);

export default Letter;
