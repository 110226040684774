import React from 'react';

const SvgAvatar = () => {
  return (
    <svg
      className="nwaEmail_email_avatar"
      height="49"
      width="49"
      viewBox="0 0 49 49"
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      clipRule="evenodd"
    >
      <g stroke="#fff">
        <circle
          cx="24.5"
          cy="24.5"
          r="21.5"
          fill="#efefef"
          fillOpacity=".247"
        />
        <path
          d="M22.015 25.747a6.428 6.428 0 0 1-3.927-5.915c0-3.519 2.893-6.415 6.412-6.418h.004c3.52 0 6.416 2.896 6.416 6.416a6.427 6.427 0 0 1-3.935 5.917c3.61.974 6.496 4.02 7.593 7.95H14.422c1.097-3.93 3.983-6.976 7.593-7.95z"
          fill="none"
        />
      </g>
    </svg>
  );
};

export default SvgAvatar;
