import React, { Component } from 'react';
import nl2br from 'react-nl2br';

import './tip/Tip.sass';

import SvgBadge from './tip/SvgBadge';
import tipclose from '../../images/icons/tip-close.svg';

class Tip extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    this.setState({ active: !this.state.active });
    this.props.notifyActiveTip(!this.state.active);
  }

  render() {
    const { onEndCall, step, pointer, index } = this.props;
    let isIn = false;
    let isExiting = false;

    let notified = false;
    const delayedEndCall = e => {
      if (notified) return;
      notified = true;
      setTimeout(() => {
        onEndCall();
      }, 0);
    };

    if (
      index === pointer ||
      (index < pointer && index + step.stay >= pointer)
    ) {
      isIn = true;
    } else if (index + step.stay + 1 === pointer) {
      isIn = true;
      isExiting = true;
    }

    let classes = 'nwaStep nwaTip';
    if (isIn) classes += ' is-in';
    if (isExiting) classes += ' is-exiting';

    if (this.state.active) classes += ' is-active';

    return (
      <div className={classes}>
        <div className="nwaTip_badge_wrapper" onClick={this.handleClick}>
          {this.state.active ? (
            <img className="nwaTip_badge" src={tipclose} alt="" />
          ) : (
            <SvgBadge />
          )}
        </div>
        <div className="nwaTip_content" onTransitionEnd={delayedEndCall}>
          {nl2br(step.meta.message)}
        </div>
      </div>
    );
  }
}

export default Tip;
