import React, { Component, Fragment } from 'react';
import { Link } from '@reach/router';
import Helmet from 'react-helmet';
import { Howl } from 'howler';

import PageWrapper from './PageWrapper';

import RIF from '../images/logos/RIF_logo.svg';
import TGL from '../images/logos/TGL_logo.svg';
import OSF from '../images/logos/opensociety_logo_white.png';
import bg from '../images/bg-gioco.min.jpg';
import arrow from '../images/icons/arrow.svg';
import hover from '../sounds/hover.mp3';
import click from '../sounds/click.mp3';

class PageProgetto extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.hover = new Howl({
      src: [hover],
    });
    this.click = new Howl({
      src: [click],
    });
  }

  handleClick() {
    this.click.play();
  }

  handleMouseEnter() {
    this.hover.play();
  }

  scrollToHash() {
    let hash = window.location.hash.replace('#', '');
    if (hash) {
      const element = document.getElementById(hash);
      if (element) {
        element.scrollIntoView();
      }
    }
  }

  componentDidMount() {
    this.scrollToHash();
  }

  componentDidUpdate() {
    this.scrollToHash();
  }

  render() {
    return (
      <Fragment>
        <Helmet>
          <title>IL PROGETTO</title>
          <meta
            name="description"
            content="“Una scelta difficile” è un progetto di sensibilizzazione sulle difficoltà e le sfide di chi segnala illeciti sul lavoro - il whistleblower."
          />
        </Helmet>
        <h1 className="is-sr-only">Il progetto</h1>
        <PageWrapper bgSrc={bg} page="progetto">
          <section className="section">
            <div className="columns">
              <div className="column is-one-third">
                <h2 className="title">Il gioco</h2>
                <p className="large">
                  “Una scelta difficile” è un progetto di sensibilizzazione
                  sulle difficoltà e le sfide che chi segnala illeciti sul
                  lavoro -&nbsp;il whistleblower&nbsp;- si trova a fronteggiare
                  quotidianamente.
                </p>
              </div>
              <div className="column is-three-fifths">
                <p>
                  Cosa succede quando sei testimone di un illecito? A chi
                  dovresti segnalarlo? Come proteggere i tuoi cari? Nel nostro
                  gioco troverai le risposte a queste ed altre domande.
                </p>
                <p>
                  Attraverso un’esperienza interattiva potrai provare in prima
                  persona cosa significa essere un whistleblower e imparare a
                  prendere le decisioni giuste nel caso ti dovessi trovare,
                  come&nbsp;
                  <Link to="/le-storie">altri prima di te</Link>, a fare “una
                  scelta difficile”.
                </p>
                <p>
                  <br />
                  <Link
                    className="button is-outlined is-game"
                    to="/"
                    onClick={this.handleClick}
                    onMouseEnter={this.handleMouseEnter}
                  >
                    <span>Sei pront* ad iniziare?</span>
                    <img src={arrow} className="svg-icon is-arrow" alt="" />
                  </Link>
                </p>
              </div>
            </div>
          </section>
          <section className="section">
            <div className="columns">
              <div className="column is-one-third">
                <h2 className="title">La campagna</h2>
              </div>
              <div className="column is-three-fifths">
                <p>
                  La protezione legale dei whistleblower in Italia è garantita
                  grazie ad una legge approvata nel 2017. Ulteriori tutele,
                  soprattutto per i lavoratori privati, saranno garantite non
                  appena il nostro Paese recepirà la direttiva europea approvata
                  nell’aprile del 2019. Due risultati che come Riparte il futuro
                  abbiamo contribuito a raggiungere grazie a due campagne (
                  <a
                    href="https://www.riparteilfuturo.it/whistleblower-italia"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Legge italiana
                  </a>
                  ,{' '}
                  <a
                    href="https://www.riparteilfuturo.it/corruzione-europa"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Direttiva europea
                  </a>
                  ) portate avanti a partire dal 2014 assieme ad altre
                  organizzazioni italiane ed europee come The Good Lobby. Più di
                  200 mila firme raccolte, flash mob, pagine e pagine di report
                  tecnici, oltre ad un costante dialogo con politici,
                  istituzioni, giornalisti e colleghi di altre nazionalità, ci
                  hanno permesso di raggiungere questi traguardi di civiltà.
                </p>
              </div>
            </div>

            <div className="columns logos">
              <div className="column is-one-third">
                <div className="logos-what">Promotore del progetto</div>
                <img src={RIF} alt="Logo dell'associazione Riparte il futuro" />
                <div className="logos-connect">
                  <a href="https://www.riparteilfuturo.it/">
                    <b>riparteilfuturo.it</b>
                  </a>
                </div>
              </div>

              <div className="column is-one-third">
                <div className="logos-what">Promotore del progetto</div>
                <img src={TGL} alt="Logo di The Good Lobby" />
                <div className="logos-connect">
                  <a href="http://thegoodlobby.eu/">
                    <b>thegoodlobby.eu</b>
                  </a>
                </div>
              </div>

              <div className="column is-one-third">
                <div className="logos-what">Sponsor del progetto</div>
                <img src={OSF} alt="Logo dell'Open Society Foundations" />
                <div className="logos-connect">
                  <a href="https://www.opensocietyfoundations.org/">
                    <b>opensocietyfoundations.org</b>
                  </a>
                </div>
              </div>
            </div>
          </section>
          <section className="section" id="disclaimer">
            <div className="columns">
              <div className="column is-one-third">
                <h2 className="title">Disclaimer</h2>
              </div>
              <div className="column is-three-fifths">
                <p>
                  Questo sito è uno strumento di primo orientamento. I suoi
                  contenuti non equivalgono ad un parere legale.
                </p>
              </div>
            </div>
          </section>
        </PageWrapper>
      </Fragment>
    );
  }
}

export default PageProgetto;
