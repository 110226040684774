import React, { Fragment } from 'react';

const TabContent0 = () => {
  return (
    <Fragment>
      <h2 className="title">
        La storia
        <br />
        di <em>Giambattista Scirè</em>
      </h2>
      <p>
        <b>Chi è?</b>
        <br />È uno storico, ricercatore e insegnante universitario di Storia
        contemporanea, nato a Vittoria (RG) nel 1975, con diverse importanti
        pubblicazioni all’attivo: “<i>La democrazia alla prova</i>” (Carocci
        2005), “<i>Il divorzio in Italia</i>” (B. Mondadori 2009), “
        <i>L’aborto in Italia. Storia di una legge</i>” (B. Mondadori 2008), “
        <i>
          Gli indipendenti di sinistra. Una storia italiana dal Sessantotto a
          Tangentopoli
        </i>
        " (Ediesse, 2012).
        <br />
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://www.linkiesta.it/it/author/giambattista-scire/71/"
        >
          Ha un blog su Linkiesta
        </a>
        .
      </p>
      <p>
        <b>Cos’è successo?</b>
        <br />
        Nel dicembre 2011 Giambattista decide di partecipare ad un concorso
        promosso dell’Università di Catania per un incarico triennale da
        ricercatore in Storia contemporanea presso la sezione distaccata di
        Ragusa.
        <br />
        Nonostante le sue qualifiche superino quelle di tutti gli altri
        candidati, il concorso viene vinto da una candidata con un curriculum e
        dei titoli del tutto inadeguati alla posizione. Dopo un primo accesso
        agli atti, Giambattista presenta diversi ricorsi amministrativi che gli
        danno ragione sotto ogni aspetto, fino a stabilire che il vero vincitore
        del concorso è lui, obbligando di fatto l’università ad un reintegro che
        però non viene mai attuato. Viene quindi aperto un processo penale sui
        fatti, con contestazioni che vanno dall’abuso d’ufficio all’elusione di
        sentenza.
        <br />
        Il 17 aprile 2019 la giustizia penale riconosce finalmente la condotta
        illecita portata avanti dai docenti della commissione giudicante ai
        danni di Scirè. I tre professori vengono così condannati a 1 anno di
        reclusione (pena sospesa) e all’interdizione dai pubblici uffici per
        abuso d’ufficio.
      </p>
      <p>
        <b>Dov'è adesso?</b>
        <br />
        Giambattista è tornato in Sicilia, per fare valere i suoi diritti e
        ottenere finalmente l’applicazione delle sentenze a suo favore.
        <br />
        Dall’inizio della vicenda, Giambattista si è ritrovato senza impiego e
        nell’impossibilità di continuare il suo lavoro di ricerca. Ha scelto
        però di condividere la sua storia pubblicamente, ad ogni livello,
        sottoponendola all’ex Ministra dell’Istruzione Fedeli, costituendosi
        parte civile nel processo penale e infine creando un’associazione, “
        <i>Trasparenza e Merito. L'Università che vogliamo</i>
        ”, assieme ad altri ricercatori che, come lui, sono stati vittime di
        ingiustizie nel mondo accademico e che oggi sono impegnati per
        un’università più trasparente e meritocratica.
      </p>
    </Fragment>
  );
};

export default TabContent0;
