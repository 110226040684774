import React, { Fragment } from 'react';

const TabContent3 = () => {
  return (
    <Fragment>
      <h2 className="title">
        La storia
        <br />
        di <em>Jonathan Sugarman</em>
      </h2>
      <p>
        <b>Chi è?</b>
        <br />
        Quello di Jonathan Sugarman è un nome noto in Irlanda e semi-sconosciuto
        in Italia. Eppure Sugarman è stato per molto tempo risk manager di
        Unicredit Ireland: il suo compito era controllare che il braccio
        irlandese della banca italiana, tra le più grandi al mondo, rispettasse
        le regole.
        <br />
        Nato in Israele, Sugarman ha lavorato al ministero degli Esteri olandese
        e si è trasferito in Irlanda nel 1999, dove ha lavorato per Microsoft
        Finance prima di approdare in Unicredit Irlanda. Esperto di analisi del
        rischio, nel 2007, poche settimane dopo essere stato assunto
        dall’istituto di credito, ha cominciato a notare alcune stranezze.
      </p>
      <p>
        <b>Cos’è successo?</b>
        <br />
        La banca per cui Sugarman lavorava era obbligata a detenere attivi e
        riserve di liquidità equivalenti al 90% delle passività, in base a una
        normativa comunitaria entrata in vigore nel 2007. Questo significava
        che, se Unicredit Ireland fosse stata esposta per 100 euro di debiti,
        avrebbe dovuto mantenere almeno 90 euro di liquidità. Sugarman,
        tuttavia, scoprì che Unicredit Irlanda violava tali requisiti detenendo
        denaro talvolta sufficiente a soddisfare appena il 70% dei suoi debiti.
      </p>
      <p>
        In qualità di risk manager decise dunque di fare rapporto ai suoi
        superiori, che gli dissero di non preoccuparsi perché le cifre sulla
        liquidità erano imprecise per via di alcuni non meglio precisati
        problemi tecnici.
        <br />
        Il rischio per Sugarman era personale: se un'autorità di vigilanza si
        fosse accorta del gioco al ribasso sulla liquidità a Unicredit sarebbe
        toccata una semplice multa ma per lui, in qualità di risk manager, si
        sarebbero spalancate le porte del carcere, con una possibile condanna a
        cinque anni per frode e omesso controllo.
      </p>
      <p>
        Era oltremodo strano che nessuna autorità di controllo si fosse accorta
        di nulla e anzi, il fatto stesso che ci fossero delle violazioni di
        questo tipo e di tale entità dimostrava in un certo senso l'indifferenza
        al problema da parte degli organismi deputati. <br />
        Per di più la situazione a lavoro era sempre più pesante: «Ero stato
        messo in una posizione tale da dovermi comportare in maniera criminale
        in quanto dipendente della banca. A nessun datore di lavoro sul pianeta
        dovrebbe essere consentito di forzare un dipendente a condurre un
        reato.» Tra risposte insufficienti ed evasive, Sugarman optò per le
        dimissioni.
      </p>
      <p>
        La segnalazione alla Banca Centrale irlandese fu inviata da Sugarman
        nell'agosto del 2007: il risk manager scoprì che le violazioni di
        Unicredit Ireland potevano raggiungere picchi del 40%. I funzionari
        della Banca Centrale avrebbero dovuto effettuare un audit
        tempestivamente ma nessuno si presentò. <br />
        «Quando cominciai a parlare con avvocati e giornalisti la banca cominciò
        a minacciarmi sia direttamente che tramite i propri legali. Alcune
        persone durante delle conferenze mi avvicinarono dicendomi “Jonathan 20
        anni fa saresti stato già morto”».
      </p>
      <p>
        Nel 2011 Jonathan Sugarman ha raccontato la sua storia all'emittente
        americana ABC e ha contestato duramente l'operato della Banca Centrale,
        sostenendo che avrebbe dovuto agire subito e non dopo 3 mesi,
        accusandola di non svolgere correttamente il proprio ruolo di controllo.
        Il senatore irlandese David Norris, in seguito, ha affermato davanti al
        Parlamento irlandese che «Unicredit si è comportata in modo grossolano e
        irresponsabile, in violazione della legge» e che «l’autorità finanziaria
        ha completamente fallito nella sua funzione di vigilanza e controllo».
        <br />
        Nel 2012 la Banca Centrale incontrò Sugarman e «in seguito a richieste
        avanzate dai media e dal Parlamento» (risalenti a due anni prima) avviò
        ulteriori indagini. <br />
        Da allora è il silenzio, sia da parte della Banca Centrale irlandese che
        di Unicredit Ireland.
      </p>
      <p>
        <b>Dov'è adesso</b>
        <br />
        Jonathan Sugarman oggi è disoccupato. Nonostante una carriera
        sfavillante, una preparazione di alto livello e una moralità
        cristallina, il settore finanziario e bancario gli è totalmente precluso
        a causa dei suoi trascorsi. <br />
        Nel novembre 2016 è intervenuto al Parlamento europeo, nel dicembre
        dello stesso anno ha pubblicato il libro “The Whistleblower”, ma
        nonostante questo non ha ancora trovato un nuovo impiego. «Sono stato in
        ospedale più di una volta in questo periodo, ho sofferto di depressione,
        di ansia. Ho perso tutto quello che avevo. Ma lo rifarei, perché almeno
        riesco a dormire la notte». Intanto il 6 febbraio 2017 è partito un
        nuovo aumento di capitale per Unicredit: 13 miliardi di euro.
      </p>
    </Fragment>
  );
};

export default TabContent3;
