import React, { memo } from 'react';
import { Link } from '@reach/router';
import { Howl } from 'howler';

import './cta/Cta.sass';

import hover from '../../sounds/hover.mp3';
import click from '../../sounds/click.mp3';
import shortGlitch from '../../sounds/short-glitch.mp3';

const Cta = ({ onEndCall, step, isIn }) => {
  // It will be the last one
  const noop = () => null;

  const hoverSound = new Howl({
    src: [hover],
  });
  const clickSound = new Howl({
    src: [click],
  });
  const shortGlitchSound = new Howl({
    src: [shortGlitch],
    loop: true,
    rate: 0.75,
  });

  const handleMouseEnter = () => hoverSound.play();
  const handleClick = () => clickSound.play();

  if (isIn && window.location.pathname === '/play') {
    shortGlitchSound.play();
    setTimeout(() => {
      shortGlitchSound.stop();
    }, 2000);
  } else shortGlitchSound.stop();

  return (
    <div className={isIn ? 'nwaStep nwaCta is-in' : 'nwaStep nwaCta'}>
      <div className="content">
        <p>
          Non per tutti è un gioco.
          <br />
          <span className="is-hidden-touch">
            Scopri chi ha avuto il coraggio di denunciare la corruzione in
            Europa.
          </span>
        </p>
        <p>
          <Link
            to="/le-storie"
            className="button is-outlined is-final"
            onAnimationEnd={noop}
            onMouseEnter={handleMouseEnter}
            onClick={handleClick}
          >
            Leggi le loro storie
          </Link>
        </p>
      </div>
    </div>
  );
};

export default memo(Cta);
