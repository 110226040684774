import React, { memo } from 'react';
import nl2br from 'react-nl2br';
import { Howl } from 'howler';

import './email/Email.sass';

import SvgAvatar from './email/SvgAvatar';
import SvgSpinner from './email/SvgSpinner';
import SvgLetter from './email/SvgLetter';
import SvgClosedLetter from './email/SvgClosedLetter';
import SvgBadge from './email/SvgBadge';

import ping from '../../sounds/ping.mp3';

const Email = ({ onEndCall, step, pointer, index }) => {
  let isIn = false;
  let isUnfocused = false;
  let isExiting = false;

  const pingSound = new Howl({
    src: [ping],
    volume: 0.7,
  });

  // For some reason if we use a timeout React (or something)
  // will fire the event twice 🤷‍
  let notified = false;
  const delayedEndCall = e => {
    if (notified) {
      return;
    }
    notified = true;
    setTimeout(() => {
      onEndCall();
    }, 3000);
  };

  if (index === pointer) {
    if (window.location.pathname === '/play') pingSound.play();
    isIn = true;
  } else if (index < pointer && index + step.stay >= pointer) {
    isIn = true;
    isUnfocused = true;
  } else if (index + step.stay + 1 === pointer) {
    isIn = true;
    isUnfocused = step.stay > 0;
    isExiting = true;
  }

  // Now let's build the class string
  let classes = 'nwaStep nwaEmail';

  if (isIn) classes += ' is-in';
  if (isUnfocused) classes += ' is-unfocused';
  if (isExiting) classes += ' is-exiting';

  return (
    <div className={classes}>
      <div className="nwaEmail_notification">
        <div className="nwaEmail_notification_bg" />
        <SvgBadge />
        <SvgClosedLetter />
        <SvgLetter />
      </div>

      <SvgSpinner />

      <div
        className="nwaEmail_email card"
        onTransitionEnd={
          isIn && !isUnfocused && !isExiting ? delayedEndCall : null
        }
      >
        <header className="nwaEmail_email_toolbar card-header">
          <span className="card-header-title">3 minuti fa</span>
          <span className="card-header-icon">
            <span className="icon-reply" />
          </span>
          <span className="card-header-icon">
            <span className="icon-star-o" />
          </span>
          <span className="card-header-icon">
            <span className="icon-trash" />
          </span>
        </header>

        <div className="nwaEmail_email_header card-content">
          <div className="media">
            <div className="media-left">
              <SvgAvatar />
            </div>
            <div className="nwaEmail_email_from media-content">
              From: {step.meta.from}
              <br />
              {step.meta.subject}
            </div>
          </div>

          <div className="nwaEmail_email_body content">
            {nl2br(step.meta.body)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(Email);
