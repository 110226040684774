import React from 'react';

const SvgDocument = () => {
  return (
    <svg
      className="nwaResearch_notification_document"
      xmlns="http://www.w3.org/2000/svg"
      width="132"
      height="172"
      viewBox="0 0 132 172"
    >
      <g fill="none" fillRule="evenodd">
        <g fill="#FFF">
          <path d="M79.78 0H24v115h84.39V28.09z" opacity=".3" />
          <path d="M36.31 28.89h27.75M36.31 17.43h27.75" />
        </g>
        <path d="M79.78 0H24v115h84.39V28.09z" />
        <path
          stroke="#65D6C6"
          strokeLinecap="round"
          strokeWidth="2"
          d="M36.31 28.89h27.75M36.31 17.43h27.75M36.31 51.8h59.77M36.3 40.34h59.77M36.3 63.28h59.77M36.3 74.77h59.77M36.3 86.25h59.77"
        />
        <path fill="#FFF" d="M80 0v28h28" opacity=".3" />
        <text
          fill="#FFF"
          fontFamily="Barlow-Medium, Barlow, Helvetica, Arial, sans-serif"
          fontSize="16"
          fontWeight="400"
          transform="translate(-1)"
        >
          <tspan x=".78" y="143">
            Regolamento_gare
          </tspan>{' '}
          <tspan x="17.32" y="166">
            _d’appalto.pdf
          </tspan>
        </text>
      </g>
    </svg>
  );
};

export default SvgDocument;
