// A "scene" is a mandated series of on-screen events ("steps")
// that end with the player having to make a choice.
const scenes = [
  {
    slug: 'start', // Holds the (descriptive) ID of the scene
    completed: 1,
    steps: [
      // Each scene can be composed of several "steps"
      {
        type: 'email', // By design, there are only so many "types" (email, chat, ...)
        meta: {
          // Everything else in a step falls under the prop "meta"
          from: 'Marianne S. <marianne.s@[[company-slug]].com>', // Hyper-simple template notation
          subject: 'Re: Appalto Ottimi Srl',
          body:
            'Tom,\ndobbiamo far vincere l’appalto a OTTIMI SERVIZI Srl anche se lavorano male. Poi ti spiego.\n\nUn bacio, Marianne',
        },
        stay: 99,
      },
      {
        type: 'narrator',
        meta: {
          messages: [
            'Questa mail che hai ricevuto per sbaglio è sospetta.',
            'Cosa vuoi fare?',
          ],
        },
        stay: 99,
      },
    ],
    choices: [
      // Like in a graph, each "scene" connects to (usually) two other
      {
        name: 'Mi faccio gli affari miei.', // The display name (it will be a button)
        value: 'a-new-email', // The slug of the scene we will be forwarded to
      },
      {
        name: 'Voglio indagare',
        value: 'dig-deeper',
      },
    ],
  },
  {
    slug: 'dig-deeper',
    completed: 3,
    steps: [
      {
        type: 'narrator',
        meta: {
          messages: ['Scrivi al tuo collega'],
        },
      },
      {
        type: 'chat',
        meta: {
          messages: [
            {
              from: 'player',
              date: '4 minuti fa',
              message:
                'Ehi [[colleague1]], conosci un’azienda che si chiama OTTIMI SERVIZI SRL? 🧐',
            },
            {
              from: '[[colleague1]]',
              date: 'ora',
              message:
                'Certo è l’azienda del marito della nostra Amministratrice Delegata.\nPerché?',
            },
          ],
        },
        stay: 99,
      },
      {
        type: 'narrator',
        meta: {
          messages: [
            'Il tuo collega sembra non sapere niente.',
            'Puoi fidarti?',
          ],
        },
        stay: 99,
      },
      {
        type: 'tip',
        meta: {
          message:
            'Nel momento in cui si viene a conoscenza di un illecito è opportuno trovare degli alleati per non rimanere isolati.\nAnche se si tende a non volersi esporre troppo, se veramente ti fidi di un collega confrontati con lui o lei.',
        },
        stay: 99,
      },
    ],
    choices: [
      {
        name: 'Mi fido',
        value: 'trust-colleague',
      },
      {
        name: 'Non mi fido',
        value: 'suspect-colleague',
      },
    ],
  },
  {
    slug: 'trust-colleague',
    completed: 6,
    steps: [
      {
        type: 'chat',
        meta: {
          messages: [
            {
              from: 'player',
              date: '2 minuti fa',
              message:
                'Penso che l’amministratrice delegata stia prendendo accordi per truccare un appalto',
            },
            {
              from: '[[colleague1]]',
              date: 'ora',
              message: '😱 Hai delle prove valide?',
            },
          ],
        },
        stay: 1,
      },
      {
        type: 'narrator',
        meta: {
          messages: [
            'La mail della AD che hai ricevuto per sbaglio è la prova di ciò che dici.',
          ],
        },
        stay: 99,
      },
    ],
    choices: [
      {
        name: 'Inoltro la mail',
        value: 'trust-colleague-2',
      },
    ],
  },
  {
    slug: 'trust-colleague-2',
    completed: 8,
    steps: [
      {
        type: 'forward',
        stay: 1,
      },
      {
        type: 'narrator',
        meta: {
          messages: ['[[colleague1]] ha ricevuto la mail'],
        },
        stay: 0,
      },
      {
        type: 'chat',
        meta: {
          messages: [
            {
              nextInteractive: true,
              from: '[[colleague1]]',
              date: '6 minuti fa',
              message:
                'Dovresti segnalare la cosa internamente in azienda. Sai come funziona?',
            },
            {
              interactive: true,
              from: 'player',
              date: '1 minuto fa',
              message: '',
            },
            {
              from: '[[colleague1]]',
              date: 'ora',
              message:
                'Nella nostra azienda devi fare segnalazioni interne prima di andare dalla polizia.',
            },
          ],
        },
        stay: 99,
      },
      {
        type: 'narrator',
        meta: {
          messages: [
            'Cosa vuoi fare?',
            'Ti fidi ancora di qualcuno in azienda?',
          ],
        },
        stay: 99,
      },
      {
        type: 'tip',
        meta: {
          message:
            'Alcune aziende hanno un programma interno di whistleblowing al quale devi segnalare potenziali illeciti prima di denunciarli alle autorità. Controlla se anche la tua azienda ne ha uno e segui la procedura, altrimenti potresti passare dalla parte del torto.',
        },
        stay: 99,
      },
    ],
    choices: [
      {
        name: 'Segnalo il fatto internamente',
        value: 'final-1',
      },
      {
        name: 'Vado direttamente dalla polizia',
        value: 'final-2',
      },
    ],
  },
  {
    slug: 'final-1',
    completed: 10,
    steps: [
      {
        type: 'final',
        element: 'phone',
        stay: 99,
      },
      {
        type: 'narrator',
        isFinal: true,
        meta: {
          messages: [
            'Ben fatto! Il tuo capo sarà presto chiamato in tribunale e processato.',
            'Molti colleghi ti hanno isolato, ma ora sai che su quelli rimasti potrai continuare a contare. Hai fatto la cosa giusta.',
          ],
        },
        stay: 99,
      },
      {
        type: 'cta',
        stay: 99,
      },
    ],
    choices: [
      {
        name: 'Gioca di nuovo',
        value: 'start',
      },
    ],
  },
  {
    slug: 'final-2',
    completed: 10,
    steps: [
      {
        type: 'final',
        element: 'newspaper',
        stay: 99,
      },
      {
        type: 'narrator',
        isFinal: true,
        meta: {
          messages: [
            'Tutti i giornali hanno parlato della tua azienda e l’Amministratrice Delegata si è dimessa.',
            'Però hai agito d’istinto, senza tutelarti, e ora l’azienda sta preparando la sua difesa con i migliori avvocati.',
          ],
        },
        stay: 99,
      },
      {
        type: 'cta',
        stay: 99,
      },
    ],
    choices: [
      {
        name: 'Gioca di nuovo',
        value: 'start',
      },
    ],
  },
  {
    slug: 'suspect-colleague',
    completed: 6,
    steps: [
      {
        type: 'chat',
        meta: {
          messages: [
            {
              from: 'player',
              date: '5 minuti fa',
              message: 'Niente, solo curiosità',
            },
            {
              from: '[[colleague1]]',
              date: 'ora',
              message: 'Mmm ok, strana domanda. Non mi piace.',
            },
          ],
        },
        stay: 99,
      },
      {
        type: 'narrator',
        meta: {
          messages: [
            'Non sembra ci si possa fidare dei tuoi colleghi.\nSe vuoi segnalare l’illecito però hai bisogno di un aiuto.',
          ],
        },
        stay: 99,
      },
    ],
    choices: [
      {
        name: 'Racconto tutto a un amico giornalista',
        value: 'suspect-colleague-2',
      },
    ],
  },
  {
    slug: 'suspect-colleague-2',
    completed: 8,
    steps: [
      {
        type: 'email',
        meta: {
          from: 'Marco P. <marco.p@quotidiano.com>',
          subject: 'Re: È successa una cosa',
          body:
            'Vuoi davvero rilasciare un’intervista?\nSe verrà pubblicata la storia avrai molta visibilità ma potranno usare le tue parole contro di te.',
        },
        stay: 99,
      },
      {
        type: 'narrator',
        meta: {
          messages: ['Il tuo amico giornalista ti ha avvisato. Che cosa fai?'],
        },
        stay: 99,
      },
      {
        type: 'tip',
        meta: {
          message:
            'Se decidi di coinvolgere la stampa prima di denunciare e di dare avvio ad un processo, sappi che ogni tua parola potrebbe essere usata contro di te o manipolata.\nGrazie all’esposizione mediatica, il caso potrebbe attirare l’attenzione che merita, ma attenzione a quello che dici e attieniti a ciò che sai per certo e che puoi dimostrare.',
        },
        stay: 99,
      },
    ],
    choices: [
      {
        name: 'Sì, voglio l’intervista',
        value: 'final-2',
      },
      {
        name: 'Ok, segnalo direttamente alla polizia',
        value: 'file-charges',
      },
    ],
  },
  {
    slug: 'file-charges',
    completed: 9,
    steps: [
      {
        type: 'email',
        meta: {
          from: 'Risorse Umane <HR@[[company-slug]].com>',
          subject: 'Nuova collocazione',
          body:
            'Gentile [[player]],\nLe comunico uno spostamento d’ufficio al piano sotterraneo e la sospensione dal suo precedente incarico.\n\nAnna, Risorse Umane',
        },
        stay: 99,
      },
      {
        type: 'tip',
        meta: {
          message:
            'Nel periodo tra la denuncia e il processo cerca di comportarti in modo ineccepibile sul posto di lavoro e mantieni un basso profilo. Ogni azione sbagliata potrebbe essere utilizzata come pretesto per allontanarti.',
        },
        stay: 99,
      },
    ],
    choices: [
      {
        name: 'Tiro un pugno nel muro',
        value: 'final-3',
      },
      {
        name: 'Nessun problema',
        value: 'final-4',
      },
    ],
  },
  {
    slug: 'final-3',
    completed: 10,
    steps: [
      {
        type: 'final',
        element: 'letter',
        stay: 99,
      },
      {
        type: 'narrator',
        isFinal: true,
        meta: {
          messages: [
            'Grazie alla tua denuncia le indagini contro il tuo capo stanno andando avanti.',
            'Tuttavia sono riusciti a licenziarti adducendo cause non legate alla tua segnalazione: insubordinazione.',
          ],
        },
        stay: 99,
      },
      {
        type: 'cta',
        stay: 99,
      },
    ],
    choices: [
      {
        name: 'Gioca di nuovo',
        value: 'start',
      },
    ],
  },
  {
    slug: 'final-4',
    completed: 10,
    steps: [
      {
        type: 'final',
        element: 'linkedin',
        stay: 99,
      },
      {
        type: 'narrator',
        isFinal: true,
        meta: {
          messages: [
            'Nonostante la tua pazienza la tua vita sul lavoro è un inferno.',
            'Superiori e colleghi ti maltrattano. Ma la tua onestà non passa inosservata: un’altra azienda ti propone un nuovo posto di lavoro. Te lo meriti!',
          ],
        },
        stay: 99,
      },
      {
        type: 'cta',
        stay: 99,
      },
    ],
    choices: [
      {
        name: 'Gioca di nuovo',
        value: 'start',
      },
    ],
  },
  {
    slug: 'a-new-email',
    completed: 3,
    steps: [
      {
        type: 'email',
        meta: {
          from: 'Tom T. <tom.t@[[company-slug]].com>',
          subject: 'Re: Re: Appalto Ottimi Srl',
          body: 'Ok, Me ne occupo io.\nNon se ne accorgerà nessuno.\n\nTom',
        },
        stay: 99,
      },
      {
        type: 'narrator',
        meta: {
          messages: [
            'Ricevi un’altra email.\nSta succedendo qualcosa di losco in azienda.\nCosa puoi fare?',
          ],
        },
        stay: 99,
      },
      {
        type: 'tip',
        meta: {
          message:
            'Prima di decidere di segnalare un illecito, parlane con la tua famiglia e le persone più vicine a te.\nAssicurati che siano dalla tua parte e credano in te, e che siano preparati ad una esposizione mediatica a cui non sono abituati.',
        },
        stay: 99,
      },
    ],
    choices: [
      {
        name: 'Ne parlo con qualcuno',
        value: 'a-new-email-2',
      },
    ],
  },
  {
    slug: 'a-new-email-2',
    completed: 4,
    steps: [
      {
        type: 'chat',
        meta: {
          messages: [
            {
              from: 'player',
              date: '8 minuti fa',
              message:
                'Ciao, devo parlarti. Ho ricevuto per sbaglio una email strana. Sembra che qui al lavoro vogliano truccare una gara. Cosa devo fare secondo te?',
            },
            {
              from: '[[relative1]]',
              date: 'Pochi istanti fa',
              message:
                'Se ne hai la certezza, fai la cosa giusta. Io sarò al tuo fianco 💪 🤗',
            },
          ],
        },
        stay: 99,
      },
      {
        type: 'narrator',
        meta: {
          messages: ['È ora di indagare.'],
        },
        stay: 99,
      },
      {
        type: 'tip',
        meta: {
          message:
            'Prima di mettere a rischio la tua vita professionale e personale informati e assicurati che il fatto che stai per denunciare sia altamente verosimile in base ai documenti in tuo possesso.\nLe opportunità di successo della tua segnalazione non sono assicurate, per cui valuta bene che la tua segnalazione porti a uno di questi risultati: esporre e prevenire ingiustizie, migliorare il tuo ambiente di lavoro futuro, il rispetto per te stesso e i tuoi valori.',
        },
        stay: 99,
      },
    ],
    choices: [
      {
        name: 'Racconto ad un collega',
        value: 'trust-colleague',
      },
      {
        name: 'Cerco prove nei file dell’azienda',
        value: 'research-evidence',
      },
    ],
  },
  {
    slug: 'research-evidence',
    completed: 7,
    steps: [
      {
        type: 'research',
        stay: 99,
      },
      {
        type: 'narrator',
        meta: {
          messages: [
            'Il regolamento delle gare d’appalto non è stato rispettato.',
            'Che vuoi fare?',
          ],
        },
        stay: 99,
      },
      {
        type: 'tip',
        meta: {
          message:
            'Se è nelle tue possibilità consulta un avvocato che possa consigliarti i migliori passi da seguire.',
        },
        stay: 99,
      },
    ],
    choices: [
      {
        name: 'Contatto l’avvocato',
        value: 'lawyer',
      },
      {
        name: 'Non ho soldi, racconto alla stampa',
        value: 'suspect-colleague-2',
      },
    ],
  },
  {
    slug: 'lawyer',
    completed: 8,
    steps: [
      // {
      //   type: 'email',
      //   meta: {
      //     from: '[[player]] <[[player-slug]]@[[company-slug]].com>',
      //     subject: 'Consulenza',
      //     body:
      //       'Caro Avv. Agnelli,\n\nHo trovato il regolamento delle gare e lo stanno aggirando.',
      //   },
      //   stay: 0,
      // },
      {
        type: 'email',
        meta: {
          from: 'Avv. De Pauli <depauli@studioavv.it>',
          subject: 'Re: Consulenza',
          body:
            'Caro [[player]], il documento che mi ha inviato contiene degli elementi non privi di pregio. Le consiglio di rivolgersi alle autorità.\n\nCordiali saluti,\nAvv. De Pauli',
        },
        stay: 99,
      },
      {
        type: 'narrator',
        meta: {
          messages: ['La risposta del tuo avvocato è chiara.'],
        },
        stay: 99,
      },
    ],
    choices: [
      {
        name: 'Ho deciso, denuncio',
        value: 'file-charges',
      },
    ],
  },
];

// Here we want 'this' to refer to the array instance
function get(slug) {
  return this.find(el => el.slug === slug);
}

// Helper for 'personalize'
function traverse(obj, callback) {
  // Some properties may be arrays of objects or of strings.
  if (Array.isArray(obj)) {
    obj.forEach((item, index) => {
      if (typeof item === 'string') {
        callback.apply(obj, [index, obj[index]]);
      } else if (typeof item === 'object') {
        for (let i in item) {
          callback.apply(item, [i, item[i]]);
          if (item[i] !== null && typeof item[i] === 'object') {
            traverse(item[i], callback);
          }
        }
      }
    });
  } else {
    for (let i in obj) {
      callback.apply(obj, [i, obj[i]]);
      if (obj[i] !== null && typeof obj[i] === 'object') {
        traverse(obj[i], callback);
      }
    }
  }
}

// Here we want 'this' to refer to the array itself
scenes.personalize = function(user) {
  const substitutions = {
    player: 'userName',
    'player-slug': 'userNameSlug',
    company: 'userCompany',
    'company-slug': 'userCompanySlug',
    colleague1: 'userColleague',
    relative1: 'userRelative',
  };

  // don't act on the original array
  const output = this.slice(0);

  traverse.call(output, output, function(prop, value) {
    // Only substitute if it's a string, duh.
    if (typeof value !== 'string') return;

    let replaced = value;
    let re;
    for (let i in substitutions) {
      re = new RegExp(`\\[\\[${i.replace('-', '\\-')}\\]\\]`, 'g');
      replaced = replaced.replace(re, user[substitutions[i]]);
    }
    this[prop] = replaced;
  });

  output.get = get;
  return output;
};

scenes.get = get;
export default scenes;
