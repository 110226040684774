import React, { Component } from 'react';
import { Link } from '@reach/router';

import './header/header.sass';

//import arrow from '../../images/icons/arrow.svg';

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      navMenuActive: false,
    };

    this.toggleMenu = this.toggleMenu.bind(this);
    this.hideMenu = this.hideMenu.bind(this);
  }

  toggleMenu() {
    this.setState({ navMenuActive: !this.state.navMenuActive });
  }

  hideMenu() {
    this.setState({ navMenuActive: false });
  }

  render() {
    const isActive = this.state.navMenuActive;

    return (
      <nav className="navbar">
        <div className="container">
          <div className="navbar-brand">
            <Link to="/" className="navbar-item logo">
              Una scelta difficile
            </Link>
            {/*
            <div className="navbar-middle navbar-trailer is-hidden-desktop">
              <a
                href="//youtube.com"
                target="_blank"
                rel="noopener noreferrer"
                className="navbar-item button is-text"
              >
                Guarda il trailer
                <img src={arrow} className="svg-icon is-arrow" alt="" />
              </a>
            </div>
            */}
            <span
              onClick={this.toggleMenu}
              className={
                isActive
                  ? 'navbar-burger burger is-active'
                  : 'navbar-burger burger'
              }
              data-target="navbarMenuHeroA"
            >
              <span />
              <span />
              <span />
            </span>
          </div>
          <div
            id="navbarMenuHeroA"
            className={isActive ? 'navbar-menu is-active' : 'navbar-menu'}
          >
            <div className="navbar-start">
              <Link onClick={this.hideMenu} to="/" className="navbar-item">
                Gioca
              </Link>
              <Link
                onClick={this.hideMenu}
                to="/come-denunciare"
                className="navbar-item"
              >
                Come denunciare
              </Link>
              <Link
                onClick={this.hideMenu}
                to="/le-storie"
                className="navbar-item"
              >
                Le storie
              </Link>
              <Link
                onClick={this.hideMenu}
                to="/il-progetto"
                className="navbar-item"
              >
                Il progetto
              </Link>
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

export default Header;
