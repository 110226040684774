import React, { Component } from 'react';

class SvgBadgeFwd extends Component {
  constructor(props) {
    super(props);

    this.circle = React.createRef();
  }

  componentDidMount() {
    const length = this.circle.current.getTotalLength() + 1;
    this.circle.current.setAttribute(
      'style',
      'stroke-dasharray: ' + length + '; stroke-dashoffset: ' + length
    );
  }

  render() {
    return (
      <svg
        className="nwaEmail_notification_fwd"
        width="54"
        height="54"
        viewBox="0 0 54 54"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <path id="nwaEmail_notification_fwd_a" d="M0 0h26v24H0z" />
        </defs>
        <g fill="none" fillRule="evenodd">
          <g transform="translate(1.47 1.29)">
            <circle
              ref={this.circle}
              stroke="#65D6C6"
              strokeWidth="2"
              cx="25.72"
              cy="25.72"
              r="25.72"
            />
            <g transform="translate(15 14)">
              <mask id="nwaEmail_notification_fwd_b" fill="#fff">
                <use xlinkHref="#nwaEmail_notification_fwd_a" />
              </mask>
              <path
                className="nwaEmail_notification_fwd_fwd"
                d="M12.968 21.864V16.67c0-.4-.26-.666-.649-.666H1.297v-7.99h11.022c.39 0 .649-.266.649-.665V2.157l11.346 9.853-11.346 9.854zm12.708-10.387L12.708.16c-.194-.2-.454-.2-.713-.066a.703.703 0 0 0-.324.599v5.992H.648C.26 6.684 0 6.95 0 7.35v9.32c0 .4.26.666.648.666h11.023v5.992c0 .267.13.466.389.6.194.133.519.066.713-.067l12.968-11.318c.13-.133.259-.333.259-.533s-.13-.4-.324-.533z"
                stroke="#FFF"
                strokeWidth=".5"
                fill="#FFF"
                mask="url(#nwaEmail_notification_fwd_b)"
              />
            </g>
            <path
              className="nwaEmail_notification_fwd_ok"
              stroke="#FFF"
              strokeWidth="2.5"
              strokeLinejoin="round"
              d="M11.14 26.65l11.62 11.98L39.4 16.15"
            />
          </g>
        </g>
      </svg>
    );
  }
}

export default SvgBadgeFwd;
