import React, { Fragment } from 'react';

const TabContent3 = () => {
  return (
    <Fragment>
      <h2 className="title">
        La storia
        <br />
        di <em>Brigitte Heinisch</em>
      </h2>
      <p>
        <b>Chi è?</b>
        <br />
        Brigitte Heinisch è un’ex-infermiera tedesca specializzata in geriatria.
        <br />È divenuta un caso esemplare di whistleblowing in Germania per
        aver denunciato il gruppo ospedaliero Vivantes Netzwerk für Gesundheit
        GmbH di cui era dipendente, accusandolo di esercitare indebite pressioni
        sui lavoratori e di non rispettare i requisiti minimi di assistenza ai
        pazienti.
      </p>
      <p>
        <b>Cos’è successo?</b>
        <br />
        Lo scandalo esplose nel 2002, quando Brigitte Heinisch prima criticò
        pubblicamente e poi denunciò il datore di lavoro sostenendo di subire
        pressioni e turni massacranti a causa di gravi carenze di personale, un
        sovraccarico di lavoro che aveva effetti a cascata sull'assistenza ai
        pazienti e agli anziani non autosufficienti.
        <br />
        Le accuse di Brigitte erano pesanti: turni impossibili per il personale,
        nessuna tutela del diritto del lavoro e costante sottodimensionamento
        degli operatori. Tuto ciò, denunciò l'infermiera, provocava
        inevitabilmente disservizi per i pazienti: molti venivano abbandonati a
        loro stessi, spesso lasciati immersi in feci e urina per pomeriggi
        interi perché non c'era nessuno per cambiarli.
      </p>
      <p>
        Quando nel 2002 Brigitte avanzò le prime osservazioni e le prime
        denunce, l’azienda cercò di mettere il personale contro la collega,
        forzando internamente una narrazione che voleva la Heinisch diffamante
        non tanto verso la Vivantes quanto più verso i colleghi operatori e la
        loro professionalità. La tecnica è sempre la stessa: mettere i colleghi
        gli uni contro gli altri, approfittando della precarietà di tutti.
        Questo atteggiamento portò l’infermiera a ritirare le denunce, cosa che
        ha permesso all'azienda, nel giro di appena un mese dalla chiusura del
        procedimento, di licenziarla senza ragione e senza preavviso, il 31
        marzo 2005.
      </p>
      <p>
        I colleghi di Brigitte si strinsero attorno alla ex collega. Le denunce
        cominciarono a fioccare: la vita quotidiana degli operatori, lo stress
        fisico e mentale cui erano sottoposti, il totale disinteresse
        dell'azienda per le prestazioni mediche ed assistenziali. Molti di loro
        si erano ammalati ed erano stati costretti a trascorrere periodi lunghi
        in degenza casalinga per l'eccessivo stress, altri non avevano retto e
        avevano preferito lasciare il lavoro.
      </p>
      <p>
        Il licenziamento diede il via a una diatriba legale che è arrivata fino
        alla Corte europea dei diritti dell'uomo di Strasburgo (CEDU). Il suo
        caso ha sicuramente contribuito a rafforzare il fronte europeo a favore
        di una direttiva comunitaria a protezione dei whistleblower: proprio
        sull’onda delle pressioni della società civile la Commissione ha
        elaborato una proposta di direttiva approvata il 16 aprile 2019.
      </p>
      <p>
        «Mi sarei resa criminalmente responsabile se avessi continuato a
        lavorare in quelle condizioni. […] volevano unicamente realizzare
        profitti, a costo di farlo sulle spalle di persone dipendenti e
        altamente vulnerabili» ha dichiarato l'ex-infermiera.
      </p>
      <p>
        Nel 2011 la CEDU le ha riconosciuto un risarcimento da 15.000 euro per
        la violazione della sua libertà di espressione (risarcimento che era
        stato precedentemente respinto dai tribunali tedeschi). Una sentenza
        storica che ha cambiato il destino, fino a quel momento nefasto,
        dell’infermiera. Secondo la CEDU la sua libertà di espressione e
        l'interesse pubblico delle informazioni da lei divulgate superava
        l'interesse dell'azienda a proteggere i propri interessi commerciali e
        la propria reputazione. Nel 2012, le parti hanno trovato un accordo
        presso il Tribunale del lavoro di Berlino.
      </p>
      <p>
        <b>Dov'è adesso</b>
        <br />
        La fine del caso Vivantes ha segnato l'inizio di tante altre storie:
        sono decine i casi successivi di whistleblower tedeschi che fanno
        riferimento esplicito al coraggio di Brigitte Heinisch. Senza lo
        scandalo Vivantes e il coraggio di Brigitte, lo scandalo Dieselgate alla
        Volkswagen, lo scandalo insider trading alla DG Bank di Francoforte, lo
        scandalo sui Döner Kebab tedeschi che utilizzavano carne avariata, non
        sarebbero probabilmente mai emersi.
        <br />
        Brigitte Heinisch si è nel frattempo scoperta disobbediente e scrittrice
        di talento (è autrice del libro "
        <i>
          Satt und Sauber? - Eine Altenpflegerin kämpft gegen den Pflegenotstand
        </i>
        “, “<i>Sazio e pulito? - Un’infermiera geriatrica contro la non cura</i>
        ”, edito solo in Germania) anche se forse ne avrebbe volentieri fatto a
        meno. <br />
        «Sono andata in pensione anticipata nel 2007 - racconta Brigitte - ho
        avuto un esaurimento nervoso. Ho passato diversi momenti di crisi e
        depressione. Ma ho imparato molto da quello che ho sofferto [...].
        Guardando indietro farei le cose in maniera diversa, magari più scaltra,
        ma rifarei quello che ho fatto.»
      </p>
    </Fragment>
  );
};

export default TabContent3;
