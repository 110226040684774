import React, { Component } from 'react';

class SvgBadge extends Component {
  constructor(props) {
    super(props);

    this.circle = null;
  }

  componentDidMount() {
    const length = this.circle.getTotalLength() + 1;
    this.circle.setAttribute(
      'style',
      'stroke-dasharray: ' + length + '; stroke-dashoffset: ' + length
    );
  }

  render() {
    return (
      <svg
        className="nwaEmail_notification_badge"
        width="54"
        height="54"
        viewBox="0 0 54 54"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <path
            d="M23.18 25.22c0 3.23-.6 5.7-1.79 7.41-1.19 1.7-2.95 2.56-5.27 2.56-2.33 0-4.07-.85-5.24-2.54-1.17-1.7-1.75-4.18-1.75-7.43 0-3.34.57-5.84 1.7-7.5 1.15-1.65 2.93-2.48 5.37-2.48 2.39 0 4.15.84 5.28 2.5 1.13 1.68 1.7 4.17 1.7 7.48zm-2.63 0c0-2.78-.33-4.78-1-6.02-.68-1.24-1.8-1.86-3.35-1.86-1.58 0-2.73.61-3.42 1.84-.7 1.22-1.04 3.23-1.04 6.04 0 2.73.35 4.72 1.06 5.98a3.56 3.56 0 0 0 3.35 1.88c1.52 0 2.63-.64 3.34-1.93.7-1.3 1.06-3.27 1.06-5.93zm-6.08 1.72v-3.57h3.37v3.57h-3.37zm13.65 7.97v-2.08h6.3V18.17c-.35.75-1.19 1.4-2.51 1.95-1.33.56-2.63.84-3.92.84v-2.13c1.42 0 2.76-.31 4.02-.93a5.66 5.66 0 0 0 2.63-2.36h2.38v17.29h5.07v2.08H28.12z"
            id="nwaEmail_notification_badge_b"
          />
          <filter
            x="-45.5%"
            y="-75.2%"
            width="191%"
            height="250.4%"
            filterUnits="objectBoundingBox"
            id="nwaEmail_notification_badge_a"
          >
            <feOffset in="SourceAlpha" result="shadowOffsetOuter1" />
            <feGaussianBlur
              stdDeviation="5"
              in="shadowOffsetOuter1"
              result="shadowBlurOuter1"
            />
            <feColorMatrix
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.188009511 0"
              in="shadowBlurOuter1"
            />
          </filter>
        </defs>
        <g fill="none" fillRule="evenodd">
          <path fill="none" d="M-790-261H650v768H-790z" />
          <g transform="translate(1.47 1.29)">
            <circle
              ref={el => {
                this.circle = el;
              }}
              stroke="#65D6C6"
              strokeWidth="2"
              cx="25.72"
              cy="25.72"
              r="25.72"
            />
            <use
              fill="#000"
              filter="url(#nwaEmail_notification_badge_a)"
              xlinkHref="#nwaEmail_notification_badge_b"
            />
            <use fill="#FAFAFA" xlinkHref="#nwaEmail_notification_badge_b" />
          </g>
        </g>
      </svg>
    );
  }
}

export default SvgBadge;
