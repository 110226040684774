import React, { Component, Fragment, memo } from 'react';
import { connect } from 'react-redux';
import { Howl } from 'howler';

import genericAvatar from '../../../images/icons/avatar.svg';

import sent from '../../../sounds/sent.mp3';
import received from '../../../sounds/received.mp3';

function randomBetween(min, max) {
  return Math.random() * (max - min) + min;
}

class Bubble extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isTyping: true,
    };
    this.typingFor = randomBetween(1500, 2500);
    this.endAfter = props.nextInteractive
      ? this.typingFor + 1000
      : this.typingFor + randomBetween(1000, 3000);
    this.inited = false;

    // sounds
    this.sent = new Howl({
      src: [sent],
    });
    this.received = new Howl({
      src: [received],
    });
  }

  getOnWithIt(isInteractive) {
    if (!isInteractive) {
      // Type for a couple of seconds
      setTimeout(() => {
        if (this.props.from === 'player') this.sent.play();
        else this.received.play();
        this.setState({ isTyping: false });
      }, this.typingFor);
    }

    // Send 'end' event after a bit
    setTimeout(() => {
      this.props.onEndCall();
    }, this.endAfter);
  }

  componentDidUpdate(prevProps, prevState) {
    const { interactive, interactiveMessage, isIn } = this.props;
    if (interactive && isIn && !interactiveMessage)
      this.props.signalInteractive();
  }

  render() {
    const {
      from,
      date,
      message,
      index,
      pointer,
      isIn,
      userAvatar,
      interactive,
      typingInteractive,
      interactiveMessage,
    } = this.props;

    if (index > pointer) return null;

    const nowInteractive = interactive && isIn;

    // Show bouncing dots if the user is typing, or nothing at all
    // (unless the message is sent!)
    if (nowInteractive && !typingInteractive && !interactiveMessage)
      return null;

    // Fake typing if it's not interactive
    if (!nowInteractive && !this.inited) {
      this.getOnWithIt(false);
      this.inited = true;
      // Send delayed end event if interactive and message sent
    } else if (nowInteractive && interactiveMessage && !this.inited) {
      this.getOnWithIt(true);
      this.inited = true;
    }

    let classes = 'message';
    classes += from === 'player' ? ' is-player is-primary' : ' is-dark';

    return (
      <div className={classes}>
        <div className="message-avatar">
          <img src={from === 'player' ? userAvatar : genericAvatar} alt="" />
        </div>
        <div className="message-body">
          {(nowInteractive && typingInteractive) ||
          (!interactive && this.state.isTyping) ? (
            <div className="typing">
              <div className="bounce1" />
              <div className="bounce2" />
              <div className="bounce3" />
            </div>
          ) : (
            <div>
              <span className="is-size-7">
                {from !== 'player' ? (
                  <Fragment>
                    <b>{from}</b> -{' '}
                  </Fragment>
                ) : null}
                {date}
              </span>
              <br />
              {message || interactiveMessage}
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({ userAvatar: state.game.user.userAvatar });

export default connect(mapStateToProps)(memo(Bubble));
