import React, { Component, Fragment } from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { navigate } from '@reach/router';
import { Animate } from 'react-animate-mount';
import { Howl } from 'howler';

import Typist from 'react-typist';
import 'react-typist/dist/Typist.css';

import PageWrapper from './PageWrapper';

import preload from '../preload';
import { updateUser, resetGame } from '../ducks/game';

import bg from '../images/bg-gioco.min.jpg';
import arrow from '../images/icons/arrow.svg';
import m from '../images/avatars/m.png';
import f from '../images/avatars/f.png';
import n from '../images/avatars/n.png';

import hover from '../sounds/hover.mp3';
import click from '../sounds/click.mp3';

const Input = ({ label, name, value, handleChange, handleMouseEnter }) => (
  <div className="field has-floating-label">
    <div className="control">
      <input
        className="input"
        type="text"
        id={`i-${name}`}
        name={name}
        value={value}
        onChange={handleChange}
        onMouseEnter={handleMouseEnter}
      />
      <label className="label" htmlFor={`i-${name}`}>
        {label}
      </label>
    </div>
  </div>
);

class PageGioca extends Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 0,
      typed0: false,
      typed1: false,
      typed2: false,
      userName: '',
      userCompany: '',
      userColleague: '',
      userRelative: '',
      avatar: false,
    };

    this.avatarRef = {
      f: React.createRef(),
      n: React.createRef(),
      m: React.createRef(),
    };

    this.handleTypingDone = this.handleTypingDone.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.advanceStep = this.advanceStep.bind(this);
    this.selectAvatar = this.selectAvatar.bind(this);

    this.hover = new Howl({
      src: [hover],
    });
    this.click = new Howl({
      src: [click],
    });
  }

  handleTypingDone() {
    let wait = 1000;
    if (window.matchMedia('(max-width: 1023px)').matches) {
      wait = 500;
    }
    setTimeout(() => {
      this.setState({ ['typed' + this.state.step]: true });
    }, wait);
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleSubmit(e) {
    if (
      this.state.userName &&
      this.state.userCompany &&
      this.state.userColleague &&
      this.state.userRelative
    ) {
      this.click.play();
      this.setState({ step: 2 });
    }
    e.preventDefault();
  }

  handleMouseEnter() {
    this.hover.play();
  }

  advanceStep() {
    this.click.play();
    this.setState({ step: 1 });
  }

  selectAvatar(type, src) {
    this.props.updateUser({
      userName: this.state.userName,
      userCompany: this.state.userCompany,
      userColleague: this.state.userColleague,
      userRelative: this.state.userRelative,
      userAvatar: src,
    });

    this.props.resetGame();
    this.click.play();

    this.avatarRef[type].current.classList.add('is-selected');

    setTimeout(() => {
      navigate('/play');
    }, 800);
  }

  componentDidMount() {
    preload([m, f, n]);
    // On mobile we hide the typewriter
    if (window.matchMedia('(max-width: 1023px)').matches) {
      this.handleTypingDone();
    }
  }

  render() {
    const { step, typed1, typed2 } = this.state;
    let classes = 'has-text-centered is-step-' + step;
    if (this.state['typed' + step]) classes += ' is-in';

    return (
      <PageWrapper classes={classes} bgSrc={bg} page="gioco">
        <Helmet>
          <title>E TU COSA FARESTI SE SCOPRISSI UN ILLECITO?</title>
          <meta
            name="description"
            content="Scopri qualcosa di sospetto sul posto di lavoro. Cosa fai? Gioca e mettiti alla prova."
          />
        </Helmet>
        {step === 2 ? (
          <Fragment key="step2">
            <Typist startDelay={250} onTypingDone={this.handleTypingDone}>
              Alza il volume e scegli il tuo avatar per cominciare:
            </Typist>
            <Animate show={typed2} type="fade">
              <div className="columns">
                <div className="column has-avatar">
                  <button
                    ref={this.avatarRef.f}
                    className="is-avatar"
                    onClick={e => this.selectAvatar('f', f)}
                    onMouseEnter={this.handleMouseEnter}
                  >
                    <img
                      src={f}
                      alt="Illustrazione: una donna con vestiti formali, occhiali e capelli raccolti"
                    />
                  </button>
                </div>
                <div className="column has-avatar">
                  <button
                    ref={this.avatarRef.n}
                    className="is-avatar"
                    onClick={e => this.selectAvatar('n', n)}
                    onMouseEnter={this.handleMouseEnter}
                  >
                    <img
                      src={n}
                      alt="Illustrazione: una persona nascosta dietro cappello e occhiali da sole"
                    />
                  </button>
                </div>
                <div className="column has-avatar">
                  <button
                    ref={this.avatarRef.m}
                    className="is-avatar"
                    onClick={e => this.selectAvatar('m', m)}
                    onMouseEnter={this.handleMouseEnter}
                  >
                    <img
                      src={m}
                      alt="Illustrazione: un uomo in golf e camicia"
                    />
                  </button>
                </div>
              </div>
            </Animate>
          </Fragment>
        ) : step === 1 ? (
          <Fragment key="step1">
            <Typist startDelay={250} onTypingDone={this.handleTypingDone}>
              Sei pronto/a a metterti nei panni di un whistleblower?
            </Typist>
            <Animate show={typed1}>
              <p className="info">
                Usa pure nomi reali{' '}
                <span role="img" aria-label="occhiolino">
                  😏
                </span>
                !<br />
                Le informazioni che inserisci non lasceranno mai il tuo browser.
                <br />
                Neanche noi sappiamo cosa scrivi.
              </p>
              <form onSubmit={this.handleSubmit}>
                <Input
                  label="Il tuo nome"
                  name="userName"
                  value={this.state.userName}
                  handleChange={this.handleChange}
                  handleMouseEnter={this.handleMouseEnter}
                />
                <Input
                  label="Nome del tuo posto di lavoro"
                  name="userCompany"
                  value={this.state.userCompany}
                  handleChange={this.handleChange}
                  handleMouseEnter={this.handleMouseEnter}
                />
                <Input
                  label="Nome di un(a) collega"
                  name="userColleague"
                  value={this.state.userColleague}
                  handleChange={this.handleChange}
                  handleMouseEnter={this.handleMouseEnter}
                />
                <Input
                  label="Nome di un(a) familiare"
                  name="userRelative"
                  value={this.state.userRelative}
                  handleChange={this.handleChange}
                  handleMouseEnter={this.handleMouseEnter}
                />
                <button
                  className="button is-outlined is-game"
                  type="submit"
                  onMouseEnter={this.handleMouseEnter}
                >
                  <span>Avanti</span>
                  <img src={arrow} className="svg-icon is-arrow" alt="" />
                </button>
              </form>
            </Animate>
          </Fragment>
        ) : (
          <Fragment key="step0">
            <p className="claim">
              Cosa faresti se scoprissi un illecito sul lavoro?
            </p>

            <h1 className="title">Una scelta difficile</h1>

            <Typist startDelay={1000} onTypingDone={this.handleTypingDone}>
              Inizia l’esperienza interattiva
            </Typist>

            <button
              className="button is-outlined is-game"
              onClick={this.advanceStep}
              onMouseEnter={this.handleMouseEnter}
            >
              <span>Mettiti alla prova</span>
              <img src={arrow} className="svg-icon is-arrow" alt="" />
            </button>
          </Fragment>
        )}
      </PageWrapper>
    );
  }
}

export default connect(
  null,
  { updateUser, resetGame }
)(PageGioca);
