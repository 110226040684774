import React, { Component, Fragment } from 'react';
import Helmet from 'react-helmet';
import { Howl } from 'howler';

import PageWrapper from './PageWrapper';

import bg from '../images/bg-gioco.min.jpg';
//import arrow from '../images/icons/arrow.svg';
import ANAC from '../images/logos/Anac_logo.png';
import Transparency from '../images/logos/TI_Logo_Png.png';
import IrpiLeaks from '../images/logos/Irpileaks_Logo_Png.png';
import ItaliaLeaks from '../images/logos/ItaliaLeaks_Logo_Png.png';

import hover from '../sounds/hover.mp3';
import click from '../sounds/click.mp3';

class PageDenunciare extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.hover = new Howl({
      src: [hover],
    });
    this.click = new Howl({
      src: [click],
    });
  }

  handleClick() {
    this.click.play();
  }

  handleMouseEnter() {
    this.hover.play();
  }

  render() {
    return (
      <Fragment>
        <Helmet>
          <title>7 COSE DA SAPERE PRIMA DI DENUNCIARE</title>
          <meta
            name="description"
            content="Hai scoperto un illecito sul lavoro? Segui attentamente questi accorgimenti e fai la cosa giusta."
          />
        </Helmet>
        <h1 className="is-sr-only">Come denunciare</h1>
        <PageWrapper bgSrc={bg} page="denunciare">
          <section className="section">
            <div className="columns">
              <div className="column is-one-third">
                <h2 className="title">7 cose da sapere prima di denunciare</h2>
              </div>
              <div className="column is-three-fifths">
                <p>
                  Quando denunci un illecito sul lavoro diventi un
                  “whistleblower”, termine inglese che letteralmente significa
                  “soffiatore di fischietto” e che, nell’ordinamento italiano,
                  viene tradotto con la formula “autore di segnalazione”.
                  <br />
                  Per denunciare un illecito di cui sei testimone devi in primo
                  luogo verificare se nella tua azienda è presente un canale
                  interno - telefonico o digitale - per ricevere segnalazioni.
                  In alternativa, puoi rivolgerti alle forze dell’ordine
                  (Carabinieri, Guardia di Finanza, o altri organi inquirenti) o
                  direttamente alla stampa, soprattutto se conosci personalmente
                  un giornalista di cui ti fidi. È possibile anche utilizzare
                  una delle piattaforme per la ricezione di segnalazioni messe a
                  disposizione da organizzazioni indipendenti, testate
                  giornalistiche o autorità statali (vedi elenco in fondo alla
                  pagina).
                </p>
                <p>
                  In generale, considera che, nel momento in cui effettui una
                  denuncia o una segnalazione, la vicenda che riveli non ti
                  appartiene più: l’hai consegnata in mani altrui, ma hai fatto
                  la cosa giusta e se segui attentamente gli accorgimenti
                  elencati di seguito puoi tutelare meglio la tua posizione.
                  Questi accorgimenti infatti sono basati sia sull’esperienza
                  pratica, sia sulle previsioni di cui alla legge 179/2017 che
                  tutela i segnalanti dalle discriminazioni e ritorsioni che
                  potrebbero subire sul posto di lavoro.
                </p>
              </div>
            </div>
            <ol className="tips">
              <li>
                <b>Verifica le tue prove</b>
                Informati e assicurati che il fatto che stai per denunciare sia
                altamente verosimile in base ai documenti in tuo possesso. La
                legge 179/2017 ti tutela quando segnali un illecito che
                pregiudica l’integrità della pubblica amministrazione, come ad
                esempio un caso di corruzione, peculato o frode. Se invece
                lavori per un ente privato, sei tutelato se segnali un reato che
                danneggia l’interesse della collettività.
                <br />
                <br />
                Se non hai la sicurezza che ciò che stai per segnalare sia
                illecito, cerca di raccogliere più informazioni e prove
                possibili prima di esporti.
                <br />
                <br />
                Nel caso in cui, invece, il reato che vuoi segnalare leda
                esclusivamente l’interesse personale tuo o di altri privati ma
                non quello della collettività, non godi delle tutele previste
                della legge a protezione dei whistleblower. In questo caso
                verifica con un avvocato i riferimenti normativi più adatti alla
                tua situazione.
              </li>
              <li>
                <b>Non isolarti</b>
                Nel momento in cui si viene a conoscenza di un illecito è
                opportuno trovare degli alleati per non rimanere isolati.
                Potresti avere paura di esporti, è normale, ma se veramente ti
                fidi di un collega ti consigliamo di confrontarti con lui o con
                lei.
              </li>
              <li>
                <b>Chiedi aiuto ai tuoi cari</b>
                Prima di decidere di segnalare un illecito, parlane con la tua
                famiglia e le persone più vicine a te. Assicurati che siano
                preparati alle possibili ritorsioni a cui potresti andare
                incontro sul lavoro (demansionamento, mobbing, licenziamento) e
                alla probabile esposizione mediatica provocata dalla tua
                segnalazione alla stampa o in caso di processo. In generale,
                stai facendo la cosa giusta, ma purtroppo potresti subire delle
                conseguenze personali per la tua segnalazione. L’appoggio della
                tua famiglia sarà fondamentale per affrontare i passi necessari
                a far valere i tuoi diritti appellandoti alla legge che tutela i
                whistleblower contro le ritorsioni e i trattamenti
                ingiustificati.
              </li>
              <li>
                <b>Scegli il canale giusto</b>
                Alcune aziende ed enti pubblici hanno un canale interno di
                whistleblowing al quale devi segnalare potenziali illeciti prima
                di denunciarli alle autorità competenti. Controlla se anche la
                tua organizzazione ne ha uno e segui la procedura, altrimenti
                potresti passare dalla parte del torto. Verifica sempre il
                livello di protezione della privacy garantito dal canale
                interno. Anche se la legge prevede che la tua riservatezza sia
                garantita e che la tua identità debba essere rivelata solo nel
                momento di un eventuale processo penale per garantire il diritto
                di difesa al segnalato, controlla lo stesso quali soggetti nello
                specifico potranno accedere ai tuoi dati e alla tua
                segnalazione. Se ritieni che affidarla a canali interni potrebbe
                pregiudicare il buon esito delle indagini, perché ad esempio
                coinvolge il top management, oppure non vuoi esporti in prima
                persona con i tuoi colleghi e superiori ma neanche recarti in
                procura o presso le forze dell’ordine, valuta la possibilità di
                utilizzare una piattaforma esterna di whistleblowing (puoi
                trovare degli esempi alla fine del testo) o di rivolgerti alla
                stampa.
              </li>
              <li>
                <b>Consulta un avvocato</b>
                Se è nelle tue possibilità consulta un avvocato giuslavorista
                che possa consigliarti i migliori passi da seguire per tutelare
                al massimo la tua posizione personale.
              </li>
              <li>
                <b>Attenzione ai rapporti con la stampa</b>
                Grazie all’esposizione mediatica, il caso potrebbe attirare
                l’attenzione che merita, ma sappi che i giornalisti potrebbero
                voler pubblicare il tuo nome. Pesa bene le tue parole quando
                parli con i media e attieniti a ciò che puoi dimostrare in base
                alla documentazione in tuo possesso: i tuoi antagonisti
                potrebbero cercare di manipolare le tue affermazioni. È molto
                importante che tutto quello che dichiari sia sempre supportato
                da fatti e prove concrete.
              </li>
              <li>
                <b>Comportati in modo ineccepibile</b>
                Nel periodo tra la denuncia e il processo cerca di comportarti
                in modo ineccepibile sul posto di lavoro e mantieni un profilo
                basso. Ogni azione sbagliata potrebbe essere utilizzata come
                pretesto per allontanarti.
              </li>
            </ol>
          </section>
          <section className="section">
            <div className="columns">
              <div className="column is-one-third">
                <h2 className="title">
                  A chi <br />
                  rivolgersi
                </h2>
              </div>
              <div className="column is-three-fifths">
                <p>
                  Esistono diverse piattaforme di whistleblowing messe a
                  disposizione da organizzazioni indipendenti, testate
                  giornalistiche o autorità statali in grado di garantire
                  l’anonimato totale del segnalante.
                </p>
                <p>
                  Se decidi di rivolgerti a una di queste realtà, ti consigliamo
                  di inviare una segnalazione il più dettagliata possibile per
                  aumentare le possibilità che venga presa in considerazione.
                </p>
              </div>
            </div>

            <div className="infocontact">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.anticorruzione.it/portal/public/classic/Servizi/Modulistica/SegnalazioniAnticorrTraspImp"
              >
                <div className="columns">
                  <div className="column is-3">
                    <img
                      src={ANAC}
                      alt="Logo di Autorità Nazionale Anticorruzione"
                    />
                  </div>
                  <div className="column is-6">
                    <h3 className="infocontact-name">
                      Autorità Nazionale Anticorruzione
                    </h3>
                    <p>
                      L’ANAC è una autorità indipendente con il mandato di
                      vigilare sull’esecuzione degli appalti pubblici e
                      prevenire la corruzione negli enti pubblici italiani,
                      anche grazie allo strumento del whistleblowing. È
                      possibile segnalare un illecito direttamente ad ANAC{' '}
                      <u>
                        compilando e inviando il modulo che puoi scaricare in
                        questa pagina
                      </u>
                      . Inoltre, ANAC è competente a decidere di eventuali cause
                      di discriminazione e mobbing sul lavoro a danno del/la
                      segnalante.
                    </p>
                  </div>
                  <div className="column is-3 has-text-centered">
                    <span className="a">anticorruzione.it</span>
                  </div>
                </div>
              </a>
            </div>

            <div className="infocontact">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.transparency.it/alac/"
              >
                <div className="columns">
                  <div className="column is-3">
                    <img
                      src={Transparency}
                      alt="Logo di Transparency International Italia"
                    />
                  </div>
                  <div className="column is-6">
                    <h3 className="infocontact-name">Alac</h3>
                    <p>
                      È la piattaforma per le segnalazioni di illeciti gestita
                      dalla ong Transparency International Italia e sviluppata
                      dalla ong Hermes Center for Transparency and Digital Human
                      Rights. Grazie alla tecnologia GlobalLeaks, la tua
                      identità rimane protetta a meno che tu non decida
                      volontariamente di renderla pubblica. I casi più gravi
                      vengono trasmessi ad Anac.{' '}
                      <u>Trovi tutte le informazioni qui</u>.
                    </p>
                  </div>
                  <div className="column is-3 has-text-centered">
                    <span className="a">transparency.it</span>
                  </div>
                </div>
              </a>
            </div>

            <div className="infocontact">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://irpi.eu/leaks/"
              >
                <div className="columns">
                  <div className="column is-3">
                    <img src={IrpiLeaks} alt="Logo di IrpiLeaks" />
                  </div>
                  <div className="column is-6">
                    <h3 className="infocontact-name">IrpiLeaks</h3>
                    <p>
                      Una piattaforma per la ricezione di segnalazioni di
                      interesse pubblico, messa a punto dai giornalisti
                      investigativi di IRPI sempre attraverso la tecnologia
                      GlobalLeaks. Questa piattaforma non permette ad IRPI di
                      rintracciarti, se non vuoi rivelare la tua identità.{' '}
                      <u>Qui trovi più informazioni e indicazioni</u> su come
                      scaricare lo strumento.
                    </p>
                  </div>
                  <div className="column is-3 has-text-centered">
                    <span className="a">irpi.eu</span>
                  </div>
                </div>
              </a>
            </div>

            <div className="infocontact">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://irpi.eu/leaks/"
              >
                <div className="columns">
                  <div className="column is-3">
                    <img src={ItaliaLeaks} alt="Logo di AGI/ItaliaLeaks" />
                  </div>
                  <div className="column is-6">
                    <h3 className="infocontact-name">ItaliaLeaks</h3>
                    <p>
                      Una piattaforma di proprietà dell’agenzia di stampa AGI
                      che utilizza la tecnologia GlobalLeaks.{' '}
                      <u>Qui trovi le informazioni</u> su come scaricarla ed
                      inviare la tua segnalazione a questi giornalisti.
                    </p>
                  </div>
                  <div className="column is-3 has-text-centered">
                    <span className="a">https://italialeaks.it/</span>
                  </div>
                </div>
              </a>
            </div>
          </section>
        </PageWrapper>
      </Fragment>
    );
  }
}

export default PageDenunciare;
