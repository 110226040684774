import React, { Fragment } from 'react';

const TabContent1 = () => {
  return (
    <Fragment>
      <h2 className="title">
        La storia
        <br />
        di <em>Raphael Rossi</em>
      </h2>
      <p>
        <b>Chi è?</b>
        <br />
        Torinese, nasce nel 1974 con doppia nazionalità, italiana e francese.
        <br />
        Laureato in lettere, fin da giovane si appassiona ai sistemi di raccolta
        differenziata di rifiuti sperimentati nei primi anni 2000 in Veneto.
        Decide di portare la sua esperienza in materia in due piccoli comuni
        nella cintura torinese, dove i risultati si dimostrano immediatamente
        positivi. Grazie alla sua esperienza nel settore viene nominato dalla
        sinistra torinese nel Cda di AMIAT, la municipalizzata che si occupa
        della raccolta dei rifiuti. Oggi è uno dei massimi esperti italiani di
        progettazione di sistemi avanzati e virtuosi di gestione rifiuti. È
        membro di numerosi comitati scientifici, tra cui quello Rifiuti Zero di
        Capannori e blogger per “Il Fatto Quotidiano”.
      </p>
      <p>
        <b>Cos’è successo?</b>
        <br />
        Da vicepresidente di AMIAT si oppone all’acquisto di un grosso
        macchinario, del valore di diversi milioni di euro, ritenendolo obsoleto
        e inutile per il lavoro svolto dalla municipalizzata. Viene così
        avvicinato dall’ex presidente di AMIAT e da altre figure dirigenziali
        della partecipata torinese che tentano di indurlo a rivedere le sue
        posizioni sull’acquisto. Per convincerlo ad appoggiare la decisione del
        consiglio di amministrazione gli offrono una tangente.
        <br />
        Raphael denuncia il tentativo di corruzione e si presta alle attività di
        indagini della procura di Torino, che successivamente avvierà un
        processo penale.
        <br />
        La storia di Raphael attrae da subito l’attenzione dei media: Report gli
        dedica più servizi e Travaglio lancia una petizione dalle pagine del
        Fatto Quotidiano affinché il Comune di Torino si schieri in difesa del
        suo giovane amministratore, costituendosi parte civile. Nasce nel
        frattempo un movimento dal basso, i “Signori Rossi”, formato da
        cittadini che si raccolgono attorno alla storia di Raphael Rossi, per
        aiutarlo nel suo percorso di denuncia e che si propone di aiutare tutte
        le persone che si trovano nella sua pozione attraverso la condivisione
        di esperienze utili. Il movimento ha sede in un locale confiscato alle
        mafie. Il processo terminerà in prescrizione dopo le sentenze di
        condanna di primo grado ai danni degli imputati.
      </p>
      <p>
        <b>Dov'è adesso</b>
        <br />
        Già nel corso del processo il nome di Raphael Rossi circola nei
        principali Comuni italiani, come uno degli esperti del ciclo dei rifiuti
        da contattare, in funzione certamente anche di una comprovata etica
        professionale. Con questo spirito viene richiesto dal neo-eletto sindaco
        di Napoli, Luigi De Magistris, per amministrare ASIA, l’azienda servizi
        di igiene ambientale locale, nel corso di un nuovo episodio dell’
        “emergenza rifiuti”. <br />A Napoli poi segue un elenco di importanti
        città in cui mette al servizio del pubblico la propria professionalità:
        da Parma a Reggio Emilia, Foggia, Messina e Reggio Calabria, dove
        amministra per conto della procura la società di raccolta rifiuti
        commissariata per mafia. Oggi è amministratore unico della Formia
        Rifiuti. Sulla sua vicenda ha scritto un libro con Alberto Robiati e
        Stefano di Polito, “
        <i>C’è chi dice no – la rivoluzione dei Signori rossi</i>“
        (Chiarelettere, 2013).
      </p>
    </Fragment>
  );
};

export default TabContent1;
