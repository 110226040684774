import React from 'react';

import linkedin from '../../../images/linkedin.min.png';

const Linkedin = () => (
  <div className="nwaFinal_linkedin">
    <img
      src={linkedin}
      alt="Si apre Linkedin: c'è un messaggio con un'offerta di lavoro per te."
    />
  </div>
);

export default Linkedin;
