import React, { Component } from 'react';
import debounce from 'lodash.debounce';

import './heroVideo/HeroVideo.sass';

class HeroVideo extends Component {
  constructor(props) {
    super();
    this.state = {
      videoReady: false,
      intervalId: null,
      imgReady: false,
      dims: {
        width: false,
        height: false,
      },
    };
    this.videoEl = React.createRef();
    this.imgEl = React.createRef();

    this.debouncedResize = debounce(this.resize.bind(this), 500);
  }

  componentDidMount() {
    const img = new Image();
    img.onload = () => {
      this.setState({ imgReady: true });
      this.resize();
    };
    img.src = this.imgEl.current.src;

    const intervalId = setInterval(this.timer.bind(this), 200);
    this.setState({ intervalId: intervalId });

    window.addEventListener('resize', this.debouncedResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.debouncedResize);
  }

  timer() {
    if (this.videoEl.current && this.videoEl.current.readyState === 4) {
      clearInterval(this.state.intervalId);
      this.setState({ videoReady: true });
      this.resize();
    }
  }

  resize() {
    if (!this.state.imgReady && !this.state.videoReady) return;

    let width, height;
    const el = this.state.videoReady
      ? this.videoEl.current
      : this.imgEl.current;

    if (!el) {
      return;
    }

    const windowRatio = window.innerWidth / window.innerHeight;
    const imgRatio = el.videoWidth
      ? el.videoWidth / el.videoHeight
      : el.offsetWidth / el.offsetHeight;

    if (windowRatio < imgRatio) {
      height = window.innerHeight;
      width = imgRatio * height;
    } else {
      width = window.innerWidth;
      height = width / imgRatio;
    }

    this.setState({ dims: { width: `${width}px`, height: `${height}px` } });
  }

  render() {
    const mp4Src = this.props.baseSrc.replace(/\.(jpg|png)$/, '.mp4');
    const webmSrc = this.props.baseSrc.replace(/\.(jpg|png)$/, '.webm');

    return (
      <div className="HeroVideo">
        <div
          className="HeroVideo_container"
          style={
            this.state.dims.width
              ? {
                  width: this.state.dims.width,
                  height: this.state.dims.height,
                }
              : null
          }
        >
          {this.props.video ? (
            <video
              className={
                this.state.videoReady
                  ? `HeroVideo_video`
                  : `HeroVideo_video is-out`
              }
              autoPlay={true}
              loop={true}
              ref={this.videoEl}
            >
              <source src={mp4Src} type="video/mp4" />
              <source src={webmSrc} type="video/webm" />
              Your browser does not support the video tag. I suggest you upgrade
              your browser.
            </video>
          ) : null}
          <img
            className={
              this.state.videoReady || !this.state.imgReady
                ? `HeroVideo_poster is-out`
                : `HeroVideo_poster`
            }
            src={this.props.baseSrc}
            alt=""
            ref={this.imgEl}
          />
        </div>
        {this.props.isGame ? <div className="HeroVideo_filter" /> : null}
        {this.props.noise ? <div className="noise" /> : null}
        {this.props.scanlines ? <div className="scanlines" /> : null}
      </div>
    );
  }
}

export default HeroVideo;
