import React, { Component } from 'react';

import './Chat.sass';
import send from '../../../images/icons/send.svg';

class ChatInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      input: '',
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
    this.props.handleChange(e);
  }

  render() {
    return (
      <div
        className={
          this.props.isVisible
            ? 'nwaChat_input content is-visible'
            : 'nwaChat_input content'
        }
      >
        <form onSubmit={this.props.handleSubmit}>
          <div className="field is-grouped">
            <div className="control is-expanded">
              <input
                className="input"
                type="text"
                name="input"
                value={this.state.value}
                autoComplete="off"
                onChange={this.handleChange}
                onBlur={this.props.handleBlur}
                placeholder="Digita la tua risposta"
              />
            </div>
            <div className="control">
              <button className="button is-outlined is-game" type="submit">
                <img src={send} className="svg-icon is-send" alt="" />
                <span>Invia</span>
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default ChatInput;
