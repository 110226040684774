import React, { memo } from 'react';
import { Howl } from 'howler';

import Typist from 'react-typist';
import 'react-typist/dist/Typist.css';

import './narrator/Narrator.sass';

import typing from '../../sounds/typing.mp3';

const Narrator = ({ onEndCall, step, pointer, index }) => {
  let isIn = false;
  let isExiting = false;

  const typeSound = new Howl({
    src: [typing],
    loop: true,
    rate: 1.7,
  });

  const conditionallyStartTypeSound = () => {
    if (!typeSound.playing()) typeSound.play();
  };

  const stopTypeSound = () => {
    typeSound.fade(1, 0, 150);
    typeSound.stop();
  };

  if (index === pointer && window.location.pathname === '/play')
    conditionallyStartTypeSound();

  const delayedEndCall = e => {
    stopTypeSound();
    setTimeout(() => {
      onEndCall();
    }, 2000);
  };

  // We want Narrator in if:
  // a. it's its turn
  // b. its turn has passed but we have a Choice next (ie: it's the second-last)
  // c. it's exiting
  if (index === pointer || (index < pointer && index + step.stay >= pointer)) {
    isIn = true;
  } else if (index + step.stay + 1 === pointer) {
    isIn = true;
    isExiting = true;
  }

  if (!isIn) return null;

  let classes = 'nwaStep nwaNarrator';
  if (step.isFinal) classes += ' is-final';
  if (isExiting) classes += ' is-exiting';

  return (
    <div className={classes}>
      <Typist
        avgTypingDelay={55}
        stdTypingDelay={25}
        onTypingDone={delayedEndCall}
        onCharacterTyped={conditionallyStartTypeSound}
        onLineTyped={stopTypeSound}
      >
        {step.meta.messages.map(
          (message, i) =>
            i === 0 ? (
              message
            ) : (
              <span key={i}>
                <Typist.Delay ms={1000} />
                <br />
                {message}
              </span>
            )
        )}
      </Typist>
    </div>
  );
};

export default memo(Narrator);
