import React, { Component, Fragment } from 'react';
import Helmet from 'react-helmet';

import PageWrapper from './PageWrapper';
import TabContent0 from './pageStorie/TabContent0';
import TabContent1 from './pageStorie/TabContent1';
import TabContent2 from './pageStorie/TabContent2';
import TabContent3 from './pageStorie/TabContent3';
import TabContent4 from './pageStorie/TabContent4';

import bg from '../images/bg-gioco.min.jpg';
import heinisch from '../images/portraits/heinisch.jpg';
import rossi from '../images/portraits/rossi.jpg';
import sugarman from '../images/portraits/sugarman.jpg';
import franzoso from '../images/portraits/franzoso.jpg';
import scire from '../images/portraits/scire.jpg';

const TabComponents = [
  TabContent0,
  TabContent1,
  TabContent2,
  TabContent3,
  TabContent4,
];

class PageStorie extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tab: 0,
    };

    this.handleTab = this.handleTab.bind(this);
  }

  handleTab(e, tab) {
    e.preventDefault();
    this.setState({ tab });
  }

  render() {
    return (
      <Fragment>
        <Helmet>
          <title>NON PER TUTTI È UN GIOCO</title>
          <meta
            name="description"
            content="Scopri le storie di 5 whistleblower."
          />
        </Helmet>
        <h1 className="is-sr-only">Le storie</h1>
        <PageWrapper bgSrc={bg} page="storie">
          <p className="title-sentence">
            Non per tutti è un gioco: scopri le loro storie
          </p>

          <div className="tabs has-people">
            <ul>
              <li className={this.state.tab === 0 ? 'is-active' : ''}>
                <a href="#mr" onClick={e => this.handleTab(e, 0)}>
                  <img src={scire} alt="Foto di Giambattista Scirè" />
                  <span>Giambattista Scirè</span>
                </a>
              </li>
              <li className={this.state.tab === 1 ? 'is-active' : ''}>
                <a href="#jc" onClick={e => this.handleTab(e, 1)}>
                  <img src={rossi} alt="Foto di Raphael Rossi" />
                  <span>Raphael Rossi</span>
                </a>
              </li>
              <li className={this.state.tab === 2 ? 'is-active' : ''}>
                <a href="#ms" onClick={e => this.handleTab(e, 2)}>
                  <img src={franzoso} alt="Foto di Andrea Franzoso" />
                  <span>Andrea Franzoso</span>
                </a>
              </li>
              <li className={this.state.tab === 3 ? 'is-active' : ''}>
                <a href="#bdb" onClick={e => this.handleTab(e, 3)}>
                  <img src={heinisch} alt="Foto di Brigitte Heinisch" />
                  <span>Brigitte Heinisch</span>
                </a>
              </li>
              <li className={this.state.tab === 4 ? 'is-active' : ''}>
                <a href="#bdb" onClick={e => this.handleTab(e, 4)}>
                  <img src={sugarman} alt="Foto di Jonathan Sugarman" />
                  <span>Jonathan Sugarman</span>
                </a>
              </li>
            </ul>
          </div>

          <section className="section tabs-content">
            {[0, 1, 2, 3, 4].map(i => {
              if (i !== this.state.tab) return null;
              const Component = TabComponents[i];
              return <Component key={i} />;
            })}
          </section>
        </PageWrapper>
      </Fragment>
    );
  }
}

export default PageStorie;
