export default function(images) {
  function doLoad() {
    setTimeout(() => {
      images.forEach(src => {
        new Image().src = src;
      });
    }, 500);
  }

  if (document.readyState === 'complete') {
    doLoad();
  } else {
    window.addEventListener('load', doLoad);
  }
}
