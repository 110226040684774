import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
//import { bindActionCreators } from 'redux';
import { Redirect } from '@reach/router';
import memoize from 'memoize-one';
import deepEqual from 'lodash.isequal';
import upperFirst from 'lodash.upperfirst';
import camelCase from 'lodash.camelcase';
import { Howl, Howler } from 'howler';

import preload from '../preload';
import originalScenes from '../scenes';
import Sequence from '../components/Sequence';
import { HeroVideo, Progress, Sound } from '../components/ui';
import {
  Choice,
  Chat,
  Email,
  Forward,
  Narrator,
  Tip,
  Research,
  Final,
  Cta,
} from '../components/steps';
import { stepForward } from '../ducks/game';

import './game/game.sass';

import newspaper from '../images/newspaper.min.png';
import letter from '../images/letter.min.png';
import linkedin from '../images/linkedin.min.png';
import iphone from '../images/iphone.min.png';
import message1 from '../images/message-1.min.png';
import message2 from '../images/message-2.min.png';
import message3 from '../images/message-3.min.png';
import message4 from '../images/message-4.min.png';
import message5 from '../images/message-5.min.png';
import soundOn from '../images/icons/sound-on.svg';
import soundOff from '../images/icons/sound-off.svg';

import office from '../sounds/office.mp3';
import drone from '../sounds/drone.mp3';

// This needs to be kept up to date manually...
// but we don't need 'Choice' since it has a special place
const stepsMap = {
  Chat,
  Email,
  Forward,
  Narrator,
  Tip,
  Research,
  Final,
  Cta,
};

class Play extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasActiveTip: false,
      sound: true,
    };
    this.personalize = memoize(
      user => originalScenes.personalize(user),
      deepEqual
    );
    this.notifyActiveTip = this.notifyActiveTip.bind(this);
    this.handleSoundClick = this.handleSoundClick.bind(this);

    // sounds
    this.office = new Howl({
      src: [office],
      volume: 0.8,
      loop: true,
    });
    this.drone = new Howl({
      src: [drone],
      volume: 0.3,
      loop: true,
    });
  }

  notifyActiveTip(active) {
    this.setState({ hasActiveTip: active });
  }

  handleSoundClick() {
    this.setState((state, props) => {
      const newSound = !state.sound;

      if (newSound) Howler.volume(1);
      else Howler.volume(0);

      return {
        sound: newSound,
      };
    });
  }

  componentDidMount() {
    preload([
      newspaper,
      letter,
      linkedin,
      iphone,
      message1,
      message2,
      message3,
      message4,
      message5,
      soundOn,
      soundOff,
    ]);
    this.office.play();
    this.drone.play();
  }

  componentWillUnmount() {
    this.office.fade(1, 0, 350);
    this.drone.fade(0.5, 0, 350);
    this.office.on('fade', () => this.office.stop());
    this.drone.on('fade', () => this.drone.stop());
  }

  render() {
    const {
      currentScene,
      currentStep,
      exitingScene,
      stepForward,
      user,
    } = this.props;

    if (!user) {
      return <Redirect to="/" noThrow />;
    }

    const scenes = this.personalize(user);
    const scene = scenes.get(currentScene);
    const isExiting = !!(currentScene === exitingScene);
    let sceneClasses = 'nwaScene';
    if (isExiting) sceneClasses += ' scene-is-exiting';
    if (this.state.hasActiveTip) sceneClasses += ' has-active-tip';

    return (
      <div className={sceneClasses}>
        <Helmet>
          <title>E TU COSA FARESTI SE SCOPRISSI UN ILLECITO?</title>
          <meta
            name="description"
            content="Scopri qualcosa di sospetto sul posto di lavoro. Cosa fai? Gioca e mettiti alla prova."
          />
        </Helmet>
        <HeroVideo
          baseSrc={`/videos/${scene.slug}.jpg`}
          video={false}
          isGame={true}
          noise={true}
          scanlines={true}
        />
        <Sound on={this.state.sound} handleClick={this.handleSoundClick} />
        <Progress completed={scene.completed} total={10} />
        {scene ? (
          <Sequence
            id={scene.slug}
            pointer={currentStep}
            doAdvance={stepForward}
          >
            {scene.steps.map((step, i) => {
              const TagName = stepsMap[upperFirst(camelCase(step.type))];
              return (
                <TagName
                  key={i}
                  step={step}
                  notifyActiveTip={this.notifyActiveTip}
                />
              );
            })}
            <Choice choices={scene.choices} />
          </Sequence>
        ) : null /* loader */}
      </div>
    );
  }
}

const mapStateToProps = state => ({ ...state.game });

const mapDispatchToProps = dispatch => {
  return {
    stepForward: () => {
      dispatch(stepForward());
    },
  };
};

/* const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({ stepForward }, dispatch),
}); */

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Play);
