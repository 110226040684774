import React, { Component, memo } from 'react';
import { Howl } from 'howler';

import Sequence from '../Sequence';
import Bubble from './chat/Bubble';
import ChatInput from './chat/ChatInput';

import './chat/Chat.sass';

import sent from '../../sounds/sent.mp3';

class Chat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showInteractive: false,
      typingInteractive: false,
      interactiveMessage: '',
    };
    this.scroller = React.createRef();
    this.signalInteractive = this.signalInteractive.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);

    // sounds
    this.sent = new Howl({
      src: [sent],
    });
  }

  signalInteractive() {
    this.setState({ showInteractive: true });
  }

  handleSubmit(e) {
    const interactiveMessage = e.currentTarget.input.value;
    if (!interactiveMessage) return;
    this.setState({ interactiveMessage, showInteractive: false });
    e.currentTarget.input.blur();
    this.sent.play();
    e.preventDefault();
  }

  handleChange(e) {
    if (e.currentTarget.value) this.setState({ typingInteractive: true });
  }

  handleBlur() {
    setTimeout(() => {
      this.setState({ typingInteractive: false });
    }, 200);
  }

  render() {
    const { onEndCall, step, pointer, index } = this.props;
    let isIn = false;
    // let isExiting = false;
    let isUnfocused = false;

    let notified = false;
    const delayedEndCall = () => {
      if (notified) return;
      notified = true;
      setTimeout(() => {
        onEndCall();
      }, 2000);
    };

    // We want Chat in if:
    if (index === pointer) {
      isIn = true;
    } else if (index < pointer && index + step.stay >= pointer) {
      isIn = true;
      isUnfocused = true;
    } else if (index + step.stay + 1 === pointer) {
      isIn = true;
      isUnfocused = step.stay > 0;
      // isExiting = true;
    }

    if (!isIn) return null;

    if (isUnfocused) {
      const el = this.scroller.current;
      if (el.scrollHeight > el.parentNode.offsetHeight) {
        setTimeout(() => {
          // Sometimes when switching Route .current is null
          if (this.scroller && this.scroller.current) {
            this.scroller.current.style.height = '100%';
            this.scroller.current.scrollTop = 1500;
          }
        }, 500);
      }
    }

    return (
      <div
        className={
          isUnfocused ? 'nwaStep nwaChat is-unfocused' : 'nwaStep nwaChat'
        }
      >
        <div className="nwaChat_container">
          <div className="nwaChat_scroller" ref={this.scroller}>
            <Sequence onSequenceDone={delayedEndCall}>
              {step.meta.messages.map((message, i) => (
                <Bubble
                  key={i}
                  signalInteractive={this.signalInteractive}
                  interactiveMessage={this.state.interactiveMessage}
                  typingInteractive={this.state.typingInteractive}
                  {...message}
                />
              ))}
            </Sequence>
          </div>
        </div>
        <ChatInput
          isVisible={this.state.showInteractive}
          handleSubmit={this.handleSubmit}
          handleChange={this.handleChange}
          handleBlur={this.handleBlur}
        />
      </div>
    );
  }
}

export default memo(Chat);
