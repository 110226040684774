import React from 'react';

const SvgLetter = () => {
  return (
    <svg
      className="nwaEmail_notification_letter"
      width="77"
      height="90"
      viewBox="0 0 77 90"
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="1.41"
    >
      <path
        d="M74.64 36.4l1.68-2.14v55H.68V35l1.68 1.39v1.23L38.5 61.71l36.14-24.03V36.4zm-.32 51.17L38.5 63.73 2.83 87.43l-.47.14h71.96zm-34.3-24.85l34.62-23v46l-34.62-23zm-37.66-23l34.62 23-34.62 23v-46z"
        fill="#65d6c6"
      />
      <path
        className="nwaEmail_notification_letter_edge"
        d="M2.36 37.4l-1.68-1v-2.14L.96 34 38.5.68l37.82 33.58-.07 2.14-1.61 1v-2.38L38.5 2.94 2.36 35.02v2.38z"
        fill="#65d6c6"
      />
      <clipPath id="nwaEmail_notification_letter_a">
        <path d="M69.95 85.8V41.6H7.05v44.15l62.9.04z" />
      </clipPath>
      <g
        className="nwaEmail_notification_letter_paper"
        clipPath="url(#nwaEmail_notification_letter_a)"
      >
        <path
          d="M7.05 40.84L38.5 61.8l31.58-21.06L76.32.7H.68l6.37 40.15z"
          fill="rgba(255, 255, 255, 0.35)"
        />
      </g>
    </svg>
  );
};

export default SvgLetter;
