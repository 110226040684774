import React from 'react';

const SvgClosedLetter = () => {
  return (
    <svg
      className="nwaEmail_notification_closedLetter"
      width="127"
      height="91"
      viewBox="0 0 127 91"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <path
          d="M.94 90.62h125.43V.27H.94v90.35zM3.73 4.4V3.1h119.85v1.3L63.65 58.15 3.73 4.4zm71.06 47.53l48.79-43.76v76.52l-48.8-32.76zM3.73 8.17l48.8 43.76L3.72 84.7V8.17zm68.9 45.7l50.54 33.93H4.08l50.6-33.93 8.97 8.05 8.98-8.05z"
          fill="#65D6C6"
        />
      </g>
    </svg>
  );
};

export default SvgClosedLetter;
