import React, { Component, Children, Fragment } from 'react';

class Sequence extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pointer: -1, // Let's give the first component the chance to animate IN
    };

    this.noop = () => null;

    this.timeout = null;

    // Handle advancements externally or internally?
    this.handleChildReady = this.props.doAdvance || this.next.bind(this);
    this.handleSequenceDone = this.props.onSequenceDone || this.noop;

    this.renderChildren = this.renderChildren.bind(this);
  }

  getPointer() {
    return this.props.pointer !== undefined
      ? this.props.pointer
      : this.state.pointer;
  }

  // Internally increases the pointer by one, if we have any child left
  next() {
    if (this.state.pointer === Children.count(this.props.children) - 1) {
      this.handleSequenceDone();
      return;
    } else if (this.state.pointer > Children.count(this.props.children) - 1) {
      return;
    }

    this.setState({ pointer: this.state.pointer + 1 });
  }

  // This always returns ALL children! It's up to the child to:
  // 1 decide whether to render based on help props 'index', 'pointer', 'isIn'
  // 2 notify end event using 'onEndCall'
  renderChildren() {
    return Children.map(this.props.children, (child, i) => {
      return React.cloneElement(child, {
        key: `seq-${this.props.id}-${i}`,
        onEndCall: this.handleChildReady,
        pointer: this.getPointer(),
        index: i,
        isIn: !!(this.getPointer() === i),
      });
    });
  }

  componentDidMount() {
    // Let's give the first component the chance to animate IN
    if (this.getPointer() === -1)
      this.timeout = setTimeout(this.handleChildReady, 800);
  }

  componentDidUpdate(prevProps, prevState) {
    // Let's give the first component the chance to animate IN
    if (this.getPointer() === -1) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(this.handleChildReady, 800);
    }
  }

  render() {
    return <Fragment>{this.renderChildren()}</Fragment>;
  }
}

export default Sequence;
