import React, { Fragment } from 'react';

const TabContent2 = () => {
  return (
    <Fragment>
      <h2 className="title">
        La storia
        <br />
        di <em>Andrea Franzoso</em>
      </h2>
      <p>
        <b>Chi è?</b>
        <br />
        Quella di Andrea Franzoso è una biografia particolare. Per dieci anni
        membro del corpo dei Carabinieri, diventa poi Internal Auditor per
        FerrovieNord, partecipata di Regione Lombardia che si occupa di
        trasporto ferroviario. Il suo compito è quello di controllare le
        procedure interne alla società, tra cui la contabilità e il rispetto
        delle norme vigenti. È febbraio 2015 quando il suo nome sale agli onori
        della cronaca come quello di un whistleblower.
      </p>
      <p>
        <b>Cos’è successo?</b>
        <br />
        Nella sua azione di internal auditing presso FerrovieNord scopre che
        l’allora Presidente della società addebitava da sette anni sulle casse
        della partecipata le sue spese personali e quelle della sua famiglia.
        Tra queste erano presenti: frutta e verdura, scarpe, locali notturni,
        viaggi e biglietti aerei, poker online, porno e multe per il figlio. Il
        tutto per una spesa complessiva di più di 400 mila euro. Decide quindi
        di segnalare quanto emerso all’interno dell’azienda ma gli viene
        risposto di non preoccuparsi e che il suo silenzio avrebbe potuto
        rappresentare per lui una buona occasione di carriera. Andrea prende la
        decisione di andare dai Carabinieri per denunciare con nome e cognome
        quanto aveva scoperto. Qualche giorno dopo il suo nome è su tutti i
        giornali.
      </p>
      <p>
        Racconta Andrea che dopo la denuncia ha attraversato diverse fasi.
        Quando il 18 maggio 2015 sono iniziati ad arrivare gli avvisi di
        garanzia riceve la solidarietà di molti colleghi, compresi quelli che
        sapevano ma non avevano denunciato. Dopo la nomina dei nuovi vertici
        aziendali Andrea mantiene formalmente i suoi incarichi, che sono però di
        fatto svolti da consulenti esterni. Successivamente viene trasferito,
        con la scusa di una riorganizzazione interna, ad un altro ufficio. Qui
        viene privato di ogni compito di controllo e attorno a lui si crea il
        vuoto: la «stragrande maggioranza dei colleghi» gli voltano le spalle.
        Viene quindi messo nelle condizioni di andarsene. Firma una risoluzione
        consensuale del rapporto di lavoro e lascia definitivamente
        FerrovieNord.
      </p>
      <p>
        A gennaio 2019 si giunge al secondo grado di giudizio nei confronti
        dell’allora Presidente della società. L’accusa di peculato stabilita in
        primo grado viene declassata ad appropriazione indebita in quanto FNM,
        società della quale FerrovieNord fa parte, non viene più riconosciuta
        come società pubblica. L’ex Presidente viene così condannato in appello
        ad una pena di 2 anni contro i 2 anni e 8 mesi del primo grado.
      </p>
      <p>
        <b>Dov'è adesso</b>
        <br />
        Dopo essersi dimesso da FerrovieNord rimane per un anno senza lavoro.
        Successivamente diventa autore televisivo di Loft, TV online del Fatto
        Quotidiano, per la quale diviene anche conduttore del programma “
        <i>Disobbedienti</i>” (2018). È inoltre autore di due libri, “
        <i>
          Il disobbediente - C’è un prezzo da pagare se non si vuole avere un
          prezzo
        </i>
        ” (PaperFIRST, 2017) e il libro per bambini e ragazzi “
        <i>#Disobbediente! - Essere onesti è la vera rivoluzione</i>” (De
        Agostini, 2018). Il 16 novembre 2018, mentre è in una scuola per il tour
        di presentazione del suo ultimo libro, riceve una chiamata nella quale
        gli viene comunicato di essere stato nominato Consigliere di
        Amministrazione di Trenord, altra società partecipata che si occupa di
        trasporto ferroviario in Lombardia. Ancora prima di questo insperato
        epilogo, alla domanda se nonostante le ripercussioni avrebbe ripetuto
        quella scelta, Andrea rispondeva: «C’è da pagare un prezzo, ma la mia
        scelta è stata razionale. Quando ho dovuto decidere se salvare la mia
        coscienza o la mia carriera, dubbi non ne ho avuti. […] Tornando
        indietro, denunciare era l’unica scelta possibile».
      </p>
    </Fragment>
  );
};

export default TabContent2;
