import { Component } from 'react';

// see: https://github.com/reach/router/issues/43
class NavigationTracker extends Component {
  componentDidMount() {
    this.props.handlePathChange(this.props.pathname);
  }

  componentDidUpdate(prevProps) {
    if (this.props.pathname !== prevProps.pathname) {
      this.props.handlePathChange(this.props.pathname);
    }
  }

  render() {
    return this.props.children || null;
  }
}

export default NavigationTracker;
