import React, { Fragment } from 'react';
import SimpleBar from 'simplebar-react';
import HeroVideo from '../components/ui/HeroVideo';
import './pages.sass';

const PageWrapper = ({ bgSrc, classes, page, children }) => {
  const finalClasses = `content is-page-${page} ${classes ? classes : ''}`;
  return (
    <Fragment>
      <HeroVideo
        baseSrc={bgSrc}
        video={false}
        noise={false}
        scanlines={false}
      />
      <div className={finalClasses}>
        <SimpleBar>{children}</SimpleBar>
      </div>
    </Fragment>
  );
};

export default PageWrapper;
