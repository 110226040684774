import React from 'react';

import soundOn from '../../images/icons/sound-on.svg';
import soundOff from '../../images/icons/sound-off.svg';

const Sound = ({ on, handleClick }) => {
  return (
    <div className="container Sound_container">
      <button
        className={`button-sound ${on ? 'is-on' : ''}`}
        onClick={handleClick}
      >
        <img src={on ? soundOn : soundOff} alt="Volume on/off" />
      </button>
    </div>
  );
};

export default Sound;
