import React, { memo } from 'react';
import { connect } from 'react-redux';
import { Howl } from 'howler';

import './choice/Choice.sass';

import { sceneChange } from '../../ducks/game';

import hover from '../../sounds/hover.mp3';
import click from '../../sounds/click.mp3';
import glitch from '../../sounds/glitch.mp3';
import shortGlitch from '../../sounds/short-glitch.mp3';

const Choice = ({ isIn, choices, chosen }) => {
  // It's always the last one
  const noop = () => null;

  const hoverSound = new Howl({
    src: [hover],
  });
  const clickSound = new Howl({
    src: [click],
  });
  const glitchSound = new Howl({
    src: [glitch],
    rate: 0.8,
  });
  const shortGlitchSound = new Howl({
    src: [shortGlitch],
  });

  if (isIn && window.location.pathname === '/play') {
    shortGlitchSound.play();
    setTimeout(() => {
      shortGlitchSound.stop();
    }, 500);
  }

  const handleMouseEnter = () => hoverSound.play();

  const handleClick = e => {
    clickSound.play();
    glitchSound.play();
    setTimeout(() => {
      glitchSound.stop();
    }, 1300);
    chosen(e);
  };

  return (
    <div
      className={
        isIn
          ? 'nwaStep nwaChoice has-text-centered is-in'
          : 'nwaStep nwaChoice has-text-centered'
      }
    >
      {choices.map(choice => (
        <button
          className="button nwaChoice_button is-outlined is-game"
          key={choice.value}
          onAnimationEnd={noop}
          onClick={handleClick}
          value={choice.value}
          onMouseEnter={handleMouseEnter}
        >
          <span>{choice.name}</span>
        </button>
      ))}
    </div>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    chosen: e => {
      dispatch(sceneChange(e.currentTarget.value));
    },
  };
};
export default connect(
  null,
  mapDispatchToProps
)(memo(Choice));
