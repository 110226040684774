import React from 'react';

const SvgClosedFolder = () => {
  return (
    <svg
      className="nwaResearch_notification_closedFolder"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="124"
      height="100"
      viewBox="0 0 124 100"
    >
      <defs>
        <path
          id="nwaResearch_notification_closedFolder_a"
          d="M0 0h123.1v100H0z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="nwaResearch_notification_closedFolder_b" fill="#fff">
          <use xlinkHref="#nwaResearch_notification_closedFolder_a" />
        </mask>
        <path
          fill="#65D6C6"
          d="M119.767 86.825c0 5.519-4.323 9.842-9.841 9.842H13.259a9.957 9.957 0 0 1-4.727-1.172c-3.07-1.648-5.115-4.876-5.115-8.671V17.412l56.105-.27h50.404c5.518 0 9.841 4.323 9.841 9.842v59.841zM3.417 13.175c0-5.518 4.324-9.841 9.842-9.841h27.16c3.443 0 6.533 1.606 8.263 4.295l3.754 5.855c.093.125.201.238.3.358l-49.319.237v-.904zm106.509.634H59.522c-1.701 0-3.313-.847-4.351-2.224L51.486 5.83C49.139 2.179 45.002 0 40.419 0h-27.16C5.871 0 .084 5.787.084 13.175v.92H.001l.083 72.73C.084 94.214 5.871 100 13.259 100h96.667c7.386 0 13.175-5.786 13.175-13.175V26.984c0-7.387-5.789-13.175-13.175-13.175z"
          mask="url(#nwaResearch_notification_closedFolder_b)"
        />
      </g>
    </svg>
  );
};

export default SvgClosedFolder;
