import React, { memo } from 'react';
import { Howl } from 'howler';

import './final/Final.sass';

import Newspaper from './final/Newspaper';
import Letter from './final/Letter';
import Linkedin from './final/Linkedin';
import Phone from './final/Phone';

import final from '../../sounds/final.mp3';

const els = {
  newspaper: Newspaper,
  letter: Letter,
  linkedin: Linkedin,
  phone: Phone,
};

const Final = ({ onEndCall, step, pointer, index }) => {
  let isIn = false;
  let isUnfocused = false;
  let isExiting = false;

  const finalSound = new Howl({
    src: [final],
    loop: true,
  });

  // For some reason if we use a timeout React (or something)
  // will fire the event twice 🤷‍
  let notified = false;
  const delayedEndCall = e => {
    if (e.target !== e.currentTarget) return;
    if (notified) {
      return;
    }
    notified = true;
    setTimeout(() => {
      onEndCall();
    }, 0);
  };

  if (index === pointer) {
    isIn = true;
    if (window.location.pathname === '/play') {
      finalSound.play();
      setTimeout(() => {
        // lower volume
        finalSound.fade(1, 0.4, 1500);
      }, 2500);
      setTimeout(() => {
        // fade out
        finalSound.fade(0.4, 0, 1500);
      }, 7500);
      setTimeout(() => {
        // and stop
        finalSound.stop();
      }, 9000);
    }
  } else if (index < pointer && index + step.stay >= pointer) {
    isIn = true;
    isUnfocused = true;
  } else if (index + step.stay + 1 === pointer) {
    isIn = true;
    isUnfocused = step.stay > 0;
    isExiting = true;
  }

  // Now let's build the class string
  let classes = `nwaStep nwaFinal is-${step.element}`;

  if (isIn) classes += ' is-in';
  else classes += ' is-out';

  if (isUnfocused) classes += ' is-unfocused';
  if (isExiting) classes += ' is-exiting';

  const Graphic = els[step.element];

  return (
    <div
      className={classes}
      onTransitionEnd={
        isIn && !isUnfocused && !isExiting ? delayedEndCall : null
      }
    >
      <Graphic />
    </div>
  );
};

export default memo(Final);
