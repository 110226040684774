import React from 'react';

const SvgSpinner = () => {
  return (
    <svg
      className="nwaEmail_spinner"
      width="45"
      height="45"
      viewBox="0.45 0 45.45 45"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <linearGradient
          x1="13.12%"
          y1="86.88%"
          y2="15.39%"
          id="nwaEmail_spinner_a"
        >
          <stop stopColor="#FFF" offset="0%" />
          <stop stopColor="#65D6C6" stopOpacity=".15" offset="100%" />
        </linearGradient>
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          d="M720.5 582.5a21 21 0 1 0 .02-41.98 21 21 0 0 0-.02 41.98z"
          stroke="url(#nwaEmail_spinner_a)"
          strokeWidth="3"
          transform="translate(-698 -539)"
        />
      </g>
    </svg>
  );
};

export default SvgSpinner;
