import React from 'react';

import './email/Email.sass';
import './forward/Forward.sass';

import SvgSpinner from './email/SvgSpinner';
import SvgLetter from './email/SvgLetter';
import SvgClosedLetter from './email/SvgClosedLetter';
import SvgBadgeFwd from './forward/SvgBadgeFwd';

const Forward = ({ onEndCall, step, pointer, index }) => {
  let isIn = false;
  let isUnfocused = false;
  let isExiting = false;

  // For some reason if we use a timeout React (or something)
  // will fire the event twice 🤷‍
  let notified = false;
  const delayedEndCall = () => {
    if (notified) {
      return;
    }
    notified = true;
    setTimeout(() => {
      onEndCall();
    }, 1500);
  };

  if (index === pointer) {
    isIn = true;
  } else if (index < pointer && index + step.stay >= pointer) {
    isIn = true;
    isUnfocused = true;
  } else if (index + step.stay + 1 === pointer) {
    isIn = true;
    isUnfocused = step.stay > 0;
    isExiting = true;
  }

  // Now let's build the class string
  let classes = 'nwaStep nwaEmail is-forward';

  if (isIn) classes += ' is-in';
  else classes += ' is-out';

  if (isUnfocused) classes += ' is-unfocused';
  if (isExiting) classes += ' is-exiting';

  return (
    <div
      className={classes}
      onTransitionEnd={
        isIn && !isUnfocused && !isExiting ? delayedEndCall : null
      }
    >
      <div className="nwaEmail_notification">
        <div className="nwaEmail_notification_bg" />
        <SvgBadgeFwd />
        <SvgClosedLetter />
        <SvgLetter />
      </div>

      <SvgSpinner />
    </div>
  );
};

export default Forward;
