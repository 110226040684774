import React, { Component } from 'react';
//import { render } from 'react-snapshot';
import { hydrate, render } from 'react-dom';
import { Provider } from 'react-redux';
import { Router, Location } from '@reach/router';
import ReactGA from 'react-ga';
import Div100vh from 'react-div-100vh';

//import 'bulma';
import './index.css';
import 'simplebar/dist/simplebar.min.css';
import store from './store';
import * as serviceWorker from './serviceWorker';

import PageGioca from './pages/PageGioca';
import PageProgetto from './pages/PageProgetto';
import PageDenunciare from './pages/PageDenunciare';
import PageStorie from './pages/PageStorie';
import Play from './pages/Play';

import NavigationTracker from './components/NavigationTracker';
import { Header, Footer } from './components/ui';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isGame: false,
    };
    this.handlePathChange = this.handlePathChange.bind(this);
    ReactGA.initialize('UA-37113834-10');
  }

  handlePathChange(newPath) {
    const sendPageView = () => {
      ReactGA.pageview(
        window.location.pathname + window.location.search + window.location.hash
      );
    };
    if (`requestAnimationFrame` in window) {
      requestAnimationFrame(() => {
        requestAnimationFrame(sendPageView);
      });
    } else {
      // simulate 2 rAF calls
      setTimeout(sendPageView, 32);
    }
    this.setState({ isGame: window.location.pathname.startsWith('/play') });
  }

  render() {
    return (
      <Provider store={store}>
        <Div100vh>
          <section
            className={
              this.state.isGame
                ? 'hero is-fullheight is-game'
                : 'hero is-fullheight'
            }
          >
            <Location>
              {({ location }) => (
                <NavigationTracker
                  handlePathChange={this.handlePathChange}
                  pathname={location.pathname}
                />
              )}
            </Location>

            <div className="hero-head">
              <Router primary={false}>
                <Header path="*" />
              </Router>
            </div>

            <div className="hero-body">
              <Router className="container">
                <PageGioca path="/" />
                <Play path="play" />
                <PageProgetto path="il-progetto" />
                <PageDenunciare path="come-denunciare" />
                <PageStorie path="le-storie" />
              </Router>
            </div>

            <div className="hero-foot">
              <Router primary={false}>
                <Footer path="*" />
              </Router>
            </div>
          </section>
        </Div100vh>
      </Provider>
    );
  }
}

loadPolyfills()
  /* Render React application now that your Polyfills are ready */
  .then(() => {
    const rootElement = document.getElementById('root');
    if (rootElement.hasChildNodes()) {
      hydrate(<App />, rootElement);
    } else {
      render(<App />, rootElement);
    }
  });

/**
 * Do feature detection, to figure out which polyfills needs to be imported.
 **/
function loadPolyfills() {
  const polyfills = [];

  if (!supportsIntersectionObserver()) {
    polyfills.push(import('intersection-observer'));
  }

  return Promise.all(polyfills);
}

function supportsIntersectionObserver() {
  return (
    'IntersectionObserver' in global &&
    'IntersectionObserverEntry' in global &&
    'intersectionRatio' in IntersectionObserverEntry.prototype
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
