import React from 'react';

import newspaper from '../../../images/newspaper.min.png';

const Newspaper = () => (
  <div className="nwaFinal_newspaper">
    <img
      src={newspaper}
      alt="Un quotidiano titola: Amministratrice delegata si dimette. Ma non viene indagata per corruzione."
    />
  </div>
);

export default Newspaper;
