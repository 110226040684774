import React from 'react';

import './progress/Progress.sass';

const Progress = ({ completed, total }) => {
  const perc = (completed / total) * 100;
  const classes = perc === 100 ? 'nwaProgress is-end' : 'nwaProgress';
  return (
    <div className={classes}>
      <div className="nwaProgress-start">Inizio</div>
      <div className="nwaProgress-bar" style={{ height: perc + '%' }} />
      <div
        className="nwaProgress-bar is-mobile"
        style={{ width: perc + '%' }}
      />
      <div className="nwaProgress-end">Fine</div>
    </div>
  );
};

export default Progress;
