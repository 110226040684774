import React from 'react';

import iphone from '../../../images/iphone.min.png';
import message1 from '../../../images/message-1.min.png';
import message2 from '../../../images/message-2.min.png';
import message3 from '../../../images/message-3.min.png';
import message4 from '../../../images/message-4.min.png';
import message5 from '../../../images/message-5.min.png';

const Phone = () => (
  <div className="nwaFinal_phone">
    <div className="nwaFinal_iphone">
      <img
        src={iphone}
        alt="Uno smartphone inizia a ricevere messaggi su messaggi."
      />
      <div className="nwaFinal_messages">
        <img className="nwaFinal_message is-message-1" src={message1} alt="" />
        <img className="nwaFinal_message is-message-2" src={message2} alt="" />
        <img className="nwaFinal_message is-message-3" src={message3} alt="" />
        <img className="nwaFinal_message is-message-4" src={message4} alt="" />
        <img className="nwaFinal_message is-message-5" src={message5} alt="" />
      </div>
    </div>
  </div>
);

export default Phone;
